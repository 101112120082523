<template>
  <div>
    <div
      :role="isAllowedToRate ? 'button' : null"
      class="c-rating-static u-flex u-flex-align-center u-hidden-print"
      v-on="isAllowedToRate ? { click: openModal } : {}"
    >
      <div class="c-rating-static__stars u-mr--xs">
        <div class="c-rating-static__unselected" />
        <div
          class="c-rating-static__selected"
          :style="{
            width: `${100/maxRating * model.averageRounded}%`
          }"
        />
      </div>
      <span class="u-font-size-medium u-mr--xs u-hidden-print">
        ({{ model.count }})
      </span>
      <a
        v-if="isAllowedToRate"
        role="button"
        class="u-font-size-medium u-hidden-print"
      >
        {{ rateButtonText }}
      </a>
    </div>

    <Modal
      v-model="isModalVisible"
      :title="model.ratingModalTitle"
    >
      <div class="u-flex u-flex-direction-column u-flex-align-center">
        <div class="c-rating u-mb--m">
          <RatingItem
            v-for="rating in maxRating"
            :key="rating"
            :rating="rating"
            :active="rating <= activeRatingLabel || (selectedRating > 0 && rating <= selectedRating)"
            @mouseover.native="activeRatingLabel = rating"
            @mouseleave.native="activeRatingLabel = 0"
            @click.native="selectedRating = rating"
          />
          <span
            v-if="activeRatingLabel > 0"
            class="c-rating__label"
          >
            {{ model.ratingLabels[activeRatingLabel - 1] }}
          </span>
        </div>
        <button
          :style="{
            'visibility': selectedRating > 0 ? 'visible' : 'hidden'
          }"
          class="c-button c-button--dark"
          @click.prevent="onSubmit"
        >
          {{ model.saveRatingButtonText }}
        </button>
      </div>
    </Modal>
    <Recaptcha
      ref="recaptchaform"
      :sitekey="model.reCaptchaSiteKey"
      @submit="saveRating"
    />
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import Recaptcha from '@/CVI/WebCore/components/Recaptcha.vue'
import api from '@/CVI/Recipe/lib/api'
import Modal from '@/CVI/WebCore/components/Modal.vue'
import RatingItem from './RatingItem.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Modal,
    Recaptcha,
    RatingItem
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    maxRating: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      isModalVisible: false,
      activeRatingLabel: 0,
      userRating: 0,
      selectedRating: 0,
      loadingUserRating: true
    }
  },
  computed: {
    isAllowedToRate() {
      return !this.loadingUserRating && (this.userRating == 0 || this.model.isAuthenticated)
    },
    rateButtonText() {
      return this.userRating > 0
        ? this.model.changeRatingButtonText
        : this.model.addRatingButtonText
    }
  },
  watch: {
    isModalVisible(visible) {
      if (!visible) {
        this.resetForm()
      }
    }
  },
  async created() {
    const userRating = await this.getUserRating()
    this.userRating = userRating || 0
    this.selectedRating = userRating || 0

    this.loadingUserRating = false
  },
  methods: {
    // TODO: this is not view logic and must be somewhere outside of component
    async getUserRating() {
      const cookie = new URLSearchParams(Cookies.get('Arlareciperating') || '')
      const rating = cookie.get(this.model.recipeId)

      if (rating || !this.model.isAuthenticated) {
        return rating
      }

      return (await api.ratings.get(this.model.recipeId)).data
    },
    async saveRating(token) {
      const { data } = await api.ratings.post(this.model.recipeId, this.selectedRating, token)
      this.model.count = data.count
      this.model.averageRounded = data.averageRounded

      this.userRating = this.selectedRating
      this.isModalVisible = false

      tracker.trackRecipeRating({
        event: 'recipeActionRating',
        recipeRating: this.selectedRating
      })
    },
    async onSubmit() {
      this.$refs.recaptchaform.verify()
    },
    resetForm() {
      this.activeRatingLabel = 0
      this.selectedRating = this.userRating
    },
    openModal() {
      this.isModalVisible = true
    }
  }
}
</script>
