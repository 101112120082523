<template>
  <x-modal
    :name="modalName"
    :duration="500"
    :adaptive="true"
    :width="640"
    :pivot-y="0"
    :click-to-close="false"
    :max-width="maxWidth"
    height="auto"
    class="c-disclaimer"
    @closed="closed"
    @opened="disableScroll"
  >
    <div
      class="v--modal__wrapper u-flex u-flex-direction-column u-flex-align-start"
    >
      <div
        class="v--modal__content c-disclaimer__content u-flex u-flex-direction-column"
      >
        <h3
          class="u-font-size-h3 u-mt--s@mobile"
        >
          {{ model.heading }}
        </h3>
        <div
          ref="container"
          class="v--modal__content--scroll"
        >
          <div class="u-flex">
            <div
              class="u-rich-text"
              v-html="model.text"
            />
          </div>
        </div>
      </div>
      <button
        class="c-button c-disclaimer__button c-button--dark u-flex-shrink-0 u-mt--m u-mt--m@mobile"
        @click="close"
      >
        {{ model.buttonText }}
      </button>
    </div>
  </x-modal>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import Cookies from 'js-cookie'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      modalsShownOnce: []
    }
  },
  computed: {
    maxWidth() {
      if (this.$mq == 'mobile') {
        return window.innerWidth
      }

      if (this.$mq == 'tablet') {
        return 480
      }

      return 640
    },
    modalName() {
      return `disclaimer-${this.model.id}`
    }
  },
  beforeDestroy() {
    enablePageScroll(this.$refs.container)
  },
  mounted() {
    const savedModals = Cookies.get('modals_shown')
    if (savedModals) {
      this.modalsShownOnce = JSON.parse(savedModals)
    }
    if (this.modalsShownOnce.indexOf(this.model.id.toString()) == -1) {
      this.$modal.show(this.modalName)
    }
  },
  methods: {
    disableScroll() {
      disablePageScroll(this.$refs.container)
    },
    closed() {
      enablePageScroll(this.$refs.container)
    },
    close() {
      this.$modal.hide(this.modalName)
      this.modalsShownOnce.push(this.model.id.toString())
      Cookies.set('modals_shown', JSON.stringify(this.modalsShownOnce), {
        expires: new Date(new Date().getTime() + (30 * 60 * 1000)),
        path: ''
      })
    }
  }
}
</script>
