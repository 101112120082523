var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-mealplan-weeks__item"},[_c('div',{staticClass:"u-flex u-flex-direction-column@mobile",class:{
      'u-flex-align-center': _vm.$mq == 'desktop',
      'u-mb--m': _vm.$mq == 'desktop',
      'u-mb--s': _vm.$mq != 'desktop'
    }},[_c('div',{staticClass:"c-mealplan-weeks__item-top u-width-720"},[_c('h2',{staticClass:"u-font-size-h3"},[_c('a',{staticClass:"u-text-nounderline",attrs:{"href":_vm.model.url}},[_vm._v(" "+_vm._s(_vm.model.heading)+" "),_c('Icon',{staticClass:"u-ml--xxs",attrs:{"icon":_vm.iconArrowRight,"size":_vm.$mq == 'desktop' ? 16 : 12,"fill":"black"}})],1)]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.model.weekNumberText))]),_c('span',{staticClass:"u-ml--xs u-mr--xs"},[_vm._v("|")]),_vm._v(_vm._s(_vm.model.weekDaysRangeText)+" "),(_vm.previousWeekLinkText)?_c('span',{staticClass:"u-ml--xs u-mr--xs"},[_vm._v("|")]):_vm._e(),(_vm.previousWeekLinkText)?_c('a',{attrs:{"href":_vm.model.url}},[_vm._v(_vm._s(_vm.previousWeekLinkText))]):_vm._e()])]),(!_vm.noRecipes)?_c('div',{class:{
        'u-mt--s': _vm.$mq != 'desktop',
        'u-ml--a': _vm.$mq == 'desktop'
      }},[_c('MealPlanButtons',{attrs:{"heading":_vm.model.heading,"buy-button-text":_vm.model.buyButtonText,"customize-button":_vm.model.customizeButton,"share-button":_vm.model.shareButton,"buy-buttons":_vm.buyButtons,"weekdays":_vm.weekDays}})],1):_vm._e()]),(!_vm.noRecipes)?_c('MealPlanCarousel',{attrs:{"recipes":_vm.model.days,"week-days":_vm.weekDays}}):_c('div',{staticClass:"u-text-align--center"},[_c('h4',[_vm._v(_vm._s(_vm.errorTitle))]),_c('p',[_vm._v(_vm._s(_vm.errorText))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }