<template>
  <Icon
    class="u-mr--xs u-ml--xs"
    :fill="active ? 'success' : 'medium-gray'"
    :size="32"
    :size-mobile="24"
    :icon="iconRating"
  />
</template>

<script>
import iconRating from '@ds/svg/icons/bold/rating-star-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    rating: {
      type: Number,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconRating
    }
  },
  computed: {
    style() {
      return {
        fontWeight: this.active ? 'bold' : 'normal'
      }
    }
  }
}
</script>
