const getSettings = (container, threeColumns) => {
  const settings = {
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    grabCursor: true,
    slidesPerView: 1,
    spaceBetween: 12,
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 16
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 16
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 32
      }
    }
  }
  if (threeColumns) {
    settings.breakpoints[1200].slidesPerView = 3
  }
  if (container.querySelectorAll('.swiper-slide').length < 10) {
    settings.pagination = {
      el: '.c-carousel-pagination',
      type: 'bullets',
      clickable: true
    }
  }
  return settings
}

export default getSettings
