<template>
  <div>
    <MealPlan
      v-if="isVisible"
      v-bind="{...$props, ...$attrs}"
    />
    <Loader
      v-if="!isVisible"
      class="u-ml--a u-mr--a"
      size="large"
      color="medium-gray"
    />
  </div>
</template>

<script>
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    Loader,
    MealPlan: () => import('@/CVI/Purchase/components/CustomMealPlan/App.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    breadcrumbs: {
      type: Object,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
    buyButtonText: {
      type: String,
      required: true
    },
    searchModal: {
      type: Object,
      required: true
    },
    selector: {
      type: Object,
      required: true
    },
    mainCourseTags: {
      type: Array,
      default: () => []
    },
    purchaseEnabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  created() {
    this.isVisible = true
  }
}
</script>
