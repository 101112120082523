<template>
  <div
    class="c-mealplan-weeks__item"
  >
    <div
      :class="{
        'u-flex-align-center': $mq == 'desktop',
        'u-mb--m': $mq == 'desktop',
        'u-mb--s': $mq != 'desktop'
      }"
      class="u-flex u-flex-direction-column@mobile"
    >
      <div class="c-mealplan-weeks__item-top u-width-720">
        <h2 class="u-font-size-h3">
          <a
            class="u-text-nounderline"
            :href="model.url"
          >
            {{ model.heading }}
            <Icon
              :icon="iconArrowRight"
              :size="$mq == 'desktop' ? 16 : 12"
              fill="black"
              class="u-ml--xxs"
            />
          </a>
        </h2>
        <div>
          <strong>{{ model.weekNumberText }}</strong>
          <span class="u-ml--xs u-mr--xs">|</span>{{ model.weekDaysRangeText }}
          <span
            v-if="previousWeekLinkText"
            class="u-ml--xs u-mr--xs"
          >|</span>
          <a
            v-if="previousWeekLinkText"
            :href="model.url"
          >{{ previousWeekLinkText }}</a>
        </div>
      </div>
      <div
        v-if="!noRecipes"
        :class="{
          'u-mt--s': $mq != 'desktop',
          'u-ml--a': $mq == 'desktop'
        }"
      >
        <MealPlanButtons
          :heading="model.heading"
          :buy-button-text="model.buyButtonText"
          :customize-button="model.customizeButton"
          :share-button="model.shareButton"
          :buy-buttons="buyButtons"
          :weekdays="weekDays"
        />
      </div>
    </div>
    <MealPlanCarousel
      v-if="!noRecipes"
      :recipes="model.days"
      :week-days="weekDays"
    />
    <div
      v-else
      class="u-text-align--center"
    >
      <h4>{{ errorTitle }}</h4>
      <p>{{ errorText }}</p>
    </div>
  </div>
</template>

<script>
import iconArrowRight from '@ds/svg/icons/bold/arrow-right-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import MealPlanButtons from '@/CVI/Purchase/components/MealPlan/MealPlanButtons.vue'
import MealPlanCarousel from '@/CVI/Purchase/components/MealPlan/MealPlanCarousel.vue'


export default {
  components: {
    Icon,
    MealPlanCarousel,
    MealPlanButtons
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    previousWeekLinkText: {
      type: String,
      required: true
    },
    weekDays: {
      type: Array,
      required: true
    },
    errorTitle: {
      type: String,
      required: true
    },
    errorText: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      iconArrowRight,
      isLoading: false,
      showMealPlan: true
    }
  },
  computed: {
    noRecipes() {
      let noRecipes = true

      if (this.model.days.length) {
        this.model.days.forEach((recipes) => {
          if (recipes.length) {
            noRecipes = false
          }
        })
      }
      return noRecipes
    },
    buyButtons() {
      return this.model.days.map(recipes => recipes[0].buyButton).filter(buyButton => buyButton)
    }
  }
}
</script>
