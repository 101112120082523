import './scss/farm-booking.scss'
import vueUtil from '@/CVI/WebCore/vue-util'
import FarmBookingAppLoader from '@/CVI/FarmBooking/components/FarmBookingAppLoader.vue'
import FarmBookingOverviewLoader from '@/CVI/FarmBooking/components/FarmBookingOverviewLoader.vue'

const components = {
  FarmBookingAppLoader,
  FarmBookingOverviewLoader
}

vueUtil.mountComponents(components)
