import './scss/facet.scss'
import './scss/facet-tag-menu.scss'
import './scss/facet-tag-related-recipes.scss'
import './scss/facet-slider.scss'
import init from './components/FacetZeroTracking'

import vueUtil from '@/CVI/WebCore/vue-util'
import FacetGrid from '@/CVI/Facet/components/FacetGrid.vue'
import FacetHero from '@/CVI/Facet/components/FacetHero.vue'
import FacetRelatedCategories from '@/CVI/Facet/components/FacetRelatedCategories.vue'
import FacetLoader from '@/CVI/Facet/components/FacetLoader.vue'
import FacetTagMenu from '@/CVI/Facet/components/FacetTagMenu.vue'
import FacetTagMenuCount from '@/CVI/Facet/components/FacetTagMenuCount.vue'
import FacetContentBlocks from '@/CVI/Facet/components/FacetContentBlocks.vue'
import FacetShowAllFacets from '@/CVI/Facet/components/FacetShowAllFacets.vue'

// order matters please note that facet hero sets intial value for facet page
const components = {
  FacetHero,
  FacetGrid,
  FacetRelatedCategories,
  FacetLoader,
  FacetTagMenu,
  FacetTagMenuCount,
  FacetContentBlocks,
  FacetShowAllFacets
}

vueUtil.mountComponents(components)

init()
