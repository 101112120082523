const getOffsetTop = (element) => {
  let offsetTop = 0
  let elm = element

  while (elm) {
    offsetTop += elm.offsetTop
    elm = elm.offsetParent
  }
  return offsetTop
}

export default getOffsetTop
