<template>
  <div class="u-flex u-mb--m u-flex-wrap u-font-size-large">
    <div
      class="c-facet-tag-related-recipes__facets u-flex u-flex-wrap"
      :class="{
        'is-opened': isOpened
      }"
    >
      <a
        v-for="(facet, index) in model"
        :key="index"
        class="c-facet-tag-related-recipes__facet"
        :href="facet.url"
        @click.prevent="onClick(facet)"
      >
        {{ facet.title }}
      </a>
    </div>
    <button
      v-if="!isOpened"
      class="c-button--no-styles c-facet-tag-related-recipes__see-more"
      @click="showAll"
    >
      {{ seeMoreFacets }}<Icon
        :icon="arrow"
        :size="8"
        fill="black"
        class="u-ml--xs"
      />
    </button>
  </div>
</template>

<script>
import arrow from '@ds/svg/icons/bold/arrow-right-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Facet/store'
import updateUrl from '@/CVI/Facet/lib/update-url'


export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Array,
      default: () => []
    },
    seeMoreFacets: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      arrow,
      store,
      isOpened: false
    }
  },
  methods: {
    showAll() {
      this.isOpened = true
    },
    async onClick(facet) {
      store.dispatch('showLoader')
      store.dispatch('setTags', { tags: facet.fullContentCriteriaTags })
      const { tagUids } = this.store.getters
      this.store.dispatch('resetCards')
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      await this.store.dispatch('loadCards', { tagUids })
      const { state } = this.store
      updateUrl.save(state)
      this.store.dispatch('hideLoader')
    }
  }
}
</script>
