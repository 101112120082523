<template>
  <span
    class="c-button c-button--small c-button--no-hover c-button--light-gray-solid c-facet__tag u-text-nowrap"
  >
    {{ model }}
    <button
      class="c-button--no-styles c-facet__remove-tag"
      @click="removeTerm()"
    >
      <Icon
        class="u-ml--xs"
        :icon="iconClose"
        :size="12"
        fill="dark"
        hover-color="dark"
      />
    </button>
  </span>
</template>

<script>
import iconClose from '@ds/svg/icons/bold/close-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      iconClose
    }
  },
  methods: {
    removeTerm() {
      this.$emit('removeTerm', this.model)
    }
  }
}
</script>
