const convertTagsToSlugs = (tags) => {
  return tags.map(tag => tag.slug)
}


const sortTags = (tagsToSort) => {
  const tags = [...tagsToSort]
  return tags.sort((a, b) => ((a.sortIndex > b.sortIndex) ? 1 : ((b.sortIndex > a.sortIndex) ? -1 : 0)))
}

const generateUrlFromState = (state) => {
  const { origin, search } = window.location
  const { basePageUrl } = window
  let { virtualSlug } = window
  const { facetSlug, tags: selectedTags } = state

  if (virtualSlug && (selectedTags.length || facetSlug)) {
    virtualSlug = `${virtualSlug}/`
  } else {
    virtualSlug = ''
  }

  let url = ''
  if (facetSlug) {
    url = `${origin}${basePageUrl}${virtualSlug}${facetSlug}/`
  } else {
    const sortedTags = sortTags(state.tags)
    const slugifiedTags = convertTagsToSlugs(sortedTags)
    const tags = slugifiedTags.length ? `${slugifiedTags.join('/')}/` : ''
    url = `${origin}${basePageUrl}${virtualSlug}${tags}`
  }

  const queryParams = new URLSearchParams(search)

  if (state.searchTerms.length) {
    queryParams.set('search', state.searchTerms.join('+'))
  }
  const queryString = queryParams.toString()
  if (queryString) {
    url += `?${queryString}`
  }
  return url
}

export default {
  save: (state) => {
    const url = generateUrlFromState(state)
    window.history.pushState(state, '', url)
  },
  replace: (state) => {
    const url = generateUrlFromState(state)
    window.history.replaceState(state, '', url)
  },
  reload: () => {
    window.location = window.basePageUrl
  }
}
