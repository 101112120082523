<template>
  <Loader
    v-if="store.state.loading || store.state.loadingMoreRecipes"
    class="u-flex u-mt--l u-mb--xl u-ml--a u-mr--a"
    size="large"
    color="medium-gray"
  />
</template>

<script>
import Loader from '@/CVI/WebCore/components/Loader.vue'
import store from '@/CVI/Facet/store'

export default {
  components: {
    Loader
  },
  data() {
    return {
      store
    }
  }
}
</script>
