<template>
  <div
    ref="container"
    class="c-hero-block u-text-white c-hero-block__no-overflow u-flex u-flex-align-center c-hero-block__no-min-height c-hero-block__overlay-to-top is-hydrated"
    :style="{
      zIndex: isSticky ? 110 : 2
    }"
  >
    <transition
      name="facet-hero"
    >
      <Picture
        v-if="imageSrc"
        :key="imageSrc.url || imageSrc"
        class="c-hero-block__background c-facet-hero__background"
        :src="imageSrc"
        :resizer="imageResizer.name"
        :alt="alt"
        :mobile="[375, 300]"
        :tablet="[768, 300]"
        :desktop="[992, 600]"
        :wide="[1920, 600]"
        :options="{ mode: 'crop'}"
      />
    </transition>
    <div class="o-content-box c-hero-block__content u-flex u-flex-direction-column u-flex-align-center u-text-align--center u-width-100">
      <div
        v-if="!heroData"
        v-html="initialContent"
      />
      <template v-else>
        <h1 class="u-flex u-flex-direction-column">
          <span class="c-label--l u-text-no-letter-spacing u-mb--xxs">
            {{ heroData.kicker }}</span>
          <span
            :class="{
              'u-font-alternative': heroData.headingFont == 'strong'
            }"
          >{{ heroData.heading }}</span>
        </h1>
        <p
          v-if="heroData.preamble"
          class="u-width-720 u-m--0 u-mb--s u-font-size-xlarge u-ml--a u-mr--a"
        >
          {{ heroData.preamble }}
        </p>
      </template>
      <SearchBar
        class="u-text-black u-mt--s"
        :model="model.searchBar"
        :filters-button-text="model.filtersButtonText"
        :is-sticky="isSticky"
        :class="{
          'u-width-100': !isSticky && $mq != 'desktop'
        }"
      />
    </div>
  </div>
</template>

<script>

import store from '@/CVI/Facet/store'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import SearchBar from '@/CVI/Facet/components/SearchBar.vue'


export default {
  components: {
    Picture,
    SearchBar
  },
  props: {
    slotContent: {
      type: String,
      default: ''
    },
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      initialContent: this.slotContent,
      isSticky: false
    }
  },
  computed: {
    backgroundImage() {
      const { heroData } = this

      if (!heroData) {
        const { backgroundImage, defaultBackgroundImage } = this.model.data

        return backgroundImage || defaultBackgroundImage
      }

      const { backgroundImage, defaultBackgroundImage } = heroData

      return backgroundImage || defaultBackgroundImage
    },
    imageSrc() {
      return this.backgroundImage.src
    },
    imageResizer() {
      return this.backgroundImage.resizer
    },
    heroData() {
      return this.store.state.heroData
    },
    alt() {
      const { heroData } = this
      return heroData ? heroData.heading : this.model.data.heading
    }
  },
  mounted() {
    this.checkIfSticky()
    window.addEventListener('scroll', this.checkIfSticky)
    window.addEventListener('resize', this.checkIfSticky)
  },
  methods: {
    checkIfSticky() {
      const { container } = this.$refs
      if (container.getBoundingClientRect().bottom < 0) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    }
  }
}
</script>

<style lang="scss">
.facet-hero-enter-active, .facet-hero-leave-active {
  transition: opacity .2s;
}
.facet-hero-enter, .facet-hero-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
