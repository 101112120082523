<template>
  <Carousel
    ref="slider"
    class="c-mealplan-weeks__carousel"
  >
    <template v-for="(recipe, index) in recipes">
      <div
        :key="recipe[0].uid"
        class="swiper-slide"
      >
        <span class="c-mealplan-weeks__label c-label--s">{{ weekDays[index] }}</span>
        <RecipeCard
          :model="recipe[0]"
          size="large"
          :desktop="[750, 420]"
          :tablet="[670, 400]"
          :mobile="[327, 185]"
          :show-loader="false"
          :show-rating="false"
          :show-image-load-state="true"
        />
      </div>
    </template>
  </Carousel>
</template>
<script>
import Carousel from '@/CVI/WebCore/components/Carousel.vue'
import RecipeCard from '@/CVI/Recipe/components/RecipeCard.vue'

export default {
  components: {
    Carousel,
    RecipeCard
  },
  props: {
    recipes: {
      type: Array,
      required: true
    },
    weekDays: {
      type: Array,
      required: true
    }
  },
  watch: {
    recipes: {
      handler() {
        this.$nextTick(() => {
          this.slider.update()
        })
      }
    }
  }
}
</script>
