<template>
  <div>
    <div
      class="u-flex u-mb--m u-flex-direction-column@mobile"
      :class="{
        'u-flex-align-center': $mq == 'desktop'
      }"
    >
      <transition
        name="fade"
        mode="out-in"
      >
        <h3
          v-if="!store.state.loading"
          class="u-m--0" v-html="heading"
        />
      </transition>
      <Dropdown
        v-model="sortOrder"
        class=" u-mt--m@mobile"
        :class="{
          'u-ml--a': $mq == 'desktop'
        }"
        :items="sortingItems"
        :disabled="!initialCards && !store.state.gridCards.length"
        @on-click="onSelectorChange"
      />
    </div>
    <transition name="fade">
      <div ref="rendered">
        <div
          v-if="initialCards"
          key="initialContent"
          v-start-with-html="initialCards"
          class="o-grid o-grid--four-columns"
        >
          <component
            :is="getComponent(card)"
            v-for="card in store.state.gridCards"
            :key="card.uid || card.kicker"
            :model="card"
          />
        </div>
        <div
          v-else
          key="content"
          class="o-grid o-grid--four-columns"
        >
          <component
            :is="getComponent(card)"
            v-for="card in store.state.gridCards"
            :key="card.uid || card.kicker"
            :model="card"
          />
        </div>
      </div>
    </transition>
    <div
      v-if="!initialCards && !store.state.gridCards.length && !store.state.loading"
      class="u-width-720 u-mt--s u-mb--s u-ml--a u-mr--a u-text-align--center"
    >
      <Icon
        :icon="iconThinkingFace"
        :size="64"
        fill="black"
        class="u-flex u-mb--s u-ml--a u-mr--a"
      />
      <div class="u-flex u-flex-direction-column">
        <strong
          v-if="model.noRecipeResultsTitle"
          class="u-font-size-h4"
        >{{ model.noRecipeResultsTitle }}</strong>
        <strong
          v-if="model.noRecipeResultsDescription"
          class="u-font-size-h5"
        >{{ model.noRecipeResultsDescription }}</strong>
      </div>
    </div>
  </div>
</template>

<script>
import iconThinkingFace from '@ds/svg/icons/bold/thinking-face.svg'

import utils from '@/CVI/WebCore/vue-util'
import store from '@/CVI/Facet/store'
import updateUrl from '@/CVI/Facet/lib/update-url'


import Dropdown from '@/CVI/WebCore/components/Dropdown.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'


import FacetCampaignCard from '@/CVI/Facet/components/FacetCampaignCard.vue'
import FacetRecipeCard from '@/CVI/Facet/components/FacetRecipeCard.vue'
import FacetEditorialCard from '@/CVI/Facet/components/FacetEditorialCard.vue'
import FacetRelatedCategories from '@/CVI/Facet/components/FacetRelatedCategories.vue'
import PurchaseCustomMealplanWidget from '@/CVI/Purchase/components/CustomMealPlan/WidgetLoader.vue'
import RecipeSaveAction from '@/CVI/Recipe/components/SaveAction.vue'
import PurchaseBuyButton from '@/CVI/Purchase/components/BuyButton.vue'
import tracking from '@/CVI/Facet/lib/tracking'

export default {
  components: {
    Icon,
    Dropdown,
    FacetCampaignCard,
    FacetEditorialCard,
    FacetRecipeCard,
    PurchaseCustomMealplanWidget,
    FacetRelatedCategories,
    RecipeSaveAction,
    PurchaseBuyButton
  },
  directives: {
    startWithHtml: {
      inserted(el, binding) {
        el.insertAdjacentHTML('afterbegin', binding.value)
      }
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    slotContent: {
      type: String,
      default: ''
    }
  },
  data() {
    const { sortOrderTexts } = this.model
    return {
      store,
      initialCards: this.slotContent.trim(),
      initialSkip: this.model.recipesCount,
      iconThinkingFace,
      refFacetContainer: null,
      sortOrder: sortOrderTexts[0].key
    }
  },
  computed: {
    sortingItems() {
      const { sortOrderTexts } = this.model
      return sortOrderTexts.map(({ key, value }) => ({ name: value, id: key }))
    },
    heading() {
      const { heading } = this.model
      const { totalCount } = this.store.state
      return heading.replace('{count}', `<span class="u-font-size-h3">${totalCount}</span>`)
    }
  },
  watch: {
    'store.state.gridCards': {
      handler(cards) {
        if (cards.length) {
          this.mountChildComponents()
        }
      }
    },
    sortOrder() {
      this.initialCards = ''
    },
    'store.state.tags': {
      handler() {
        this.initialCards = ''
      }
    },
    'store.state.searchTerms': {
      handler() {
        this.initialCards = ''
      }
    }
  },
  mounted() {
    this.mountChildComponents()
    this.refFacetContainer = document.querySelector('.js-facet-container')
    this.setupInitalState()
    window.addEventListener('scroll', this.loadMoreRecipes)
    this.loadMoreRecipes()
    window.addEventListener('popstate', this.restoreState)
    const { totalCount } = this.model.cards
    tracking.recipeAmount(totalCount)
    if (this.initialCards) {
      this.addTrackingForServerRenderedElements()
    }
  },
  methods: {
    setupInitalState() {
      const { initialSkip } = this
      const { sortOrderTexts } = this.model
      this.store.dispatch('setTotalCount', { count: this.model.cards.totalCount })
      this.store.dispatch('setPageSize', { pageSize: initialSkip })
      this.store.dispatch('updateSkip', { skip: initialSkip })
      this.store.dispatch('setSortOrder', { sortOrder: sortOrderTexts[0].key })
      // save state
      const { state } = this.store
      updateUrl.replace(state)
    },
    mountChildComponents() {
      utils.mountChildComponents({
        PurchaseCustomMealplanWidget,
        FacetEditorialCard,
        FacetCampaignCard,
        FacetRelatedCategories,
        PurchaseBuyButton,
        RecipeSaveAction
      }, null, this.$refs.rendered)
    },
    getComponent(card) {
      if (card.type == 'recipe') {
        return FacetRecipeCard
      }
      if (card.type == 'page') {
        return FacetEditorialCard
      }
      if (card.imageSize) {
        return FacetCampaignCard
      }
      if (card.relatedFacets) {
        return FacetRelatedCategories
      }
      return PurchaseCustomMealplanWidget
    },
    async onSelectorChange(sortOrder) {
      this.store.dispatch('resetCards') // hide cards and show loader first
      this.store.dispatch('showLoader')
      this.store.dispatch('setSortOrder', { sortOrder })
      const { tagUids } = this.store.getters
      await this.store.dispatch('loadCards', { tagUids })
      this.store.dispatch('hideLoader')
    },
    async loadMoreRecipes() {
      const {
        refFacetContainer
      } = this

      const offset = 2.3
      const isUIneedMoreItems = refFacetContainer
        .getBoundingClientRect().bottom <= window.innerHeight * offset

      const { skip, tagUids } = this.store.getters
      const { hasMoreResults, loading, loadingMoreRecipes } = this.store.state

      if (isUIneedMoreItems && !loadingMoreRecipes && hasMoreResults && !loading) {
        await this.store.dispatch('getMoreRecipes', { skip, tagUids })
      }
    },
    async restoreState({ state }) {
      this.store.dispatch('resetCards') // hide cards and show loader first
      this.store.dispatch('setStateFromUrl', { state })
      this.store.dispatch('showLoader')
      const { tagUids, isFacet0 } = this.store.getters
      if (isFacet0) {
        updateUrl.reload()
      } else {
        await this.store.dispatch('loadCards', { tagUids })
        this.store.dispatch('hideLoader')
      }
    },
    addTrackingForServerRenderedElements() {
      this.$refs.rendered.querySelectorAll('.c-card').forEach((item) => {
        const title = item.dataset.trackingTitle
        item.addEventListener('click', () => {
          tracking.selectRecipe(title)
        })
      })
    }
  }
}
</script>
