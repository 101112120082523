import './polyfills'
import './icons'

import Vue from 'vue'
import VueMq from 'vue-mq'
import VModal from 'vue-js-modal'
import Vuelidate from 'vuelidate'
import vueUtil from './vue-util'

// Vanilla scripts
import { stickyHeader } from '@/CVI/WebCore/components/Header'
import headerDirection from '@/CVI/WebCore/components/HeaderDirection'
import carousel from '@/CVI/WebCore/components/Carousel'
import grid from '@/CVI/WebCore/components/Grid'
import detectImageLoaded from '@/CVI/WebCore/components/detect-image-loaded'
import '@/CVI/WebCore/lib/login-redirect'

// webcore
import WebCoreMainMenu from '@/CVI/WebCore/components/Navigation/MainMenu.vue'
import WebCoreServiceMenu from '@/CVI/WebCore/components/Navigation/ServiceMenu/ServiceMenu.vue'
import WebCoreCoupon from '@/CVI/WebCore/components/Coupon.vue'
import WebCoreFaqAccordion from '@/CVI/WebCore/components/FaqAccordion.vue'
import WebCoreDisclaimer from '@/CVI/WebCore/components/Disclaimer.vue'
import WebCoreGrid from '@/CVI/WebCore/components/Grid.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import YoutubeVideoButton from '@/CVI/WebCore/components/YoutubeVideoButton.vue'
import SeoLinks from '@/CVI/WebCore/components/SeoLinks.vue'
import PageMenu from '@/CVI/WebCore/components/PageMenu.vue'
import SignUp from '@/CVI/WebCore/components/SignUpLoader.vue'
import WebCoreComments from '@/CVI/WebCore/components/Comments.vue'
import WebCoreClearOnCoupon from '@/CVI/WebCore/components/ClearOnCouponLoader.vue'
import WebCoreHtmlFullWidthBlock from '@/CVI/WebCore/components/HtmlFullWidthBlock.vue'
import WebCoreScrollToButton from '@/CVI/WebCore/components/ScrollToButton.vue'
import WebCoreTabbedContent from '@/CVI/WebCore/components/TabbedContent.vue'
import noFollowLink from '@/CVI/WebCore/components/NoFollowLink'


// create and dispatch the event
const readyEvent = new CustomEvent('app-ready')
window.dispatchEvent(readyEvent)

Vue.use(Vuelidate)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 992,
    desktop: Infinity
  }
})

Vue.use(VModal, {
  componentName: 'x-modal'
})

const components = {
  // webcore
  WebCoreMainMenu,
  WebCoreServiceMenu,
  WebCoreCoupon,
  WebCoreFaqAccordion,
  WebCoreDisclaimer,
  WebCoreGrid,
  WebCoreComments,
  WebCoreClearOnCoupon,
  WebCoreHtmlFullWidthBlock,
  WebCoreScrollToButton,
  WebCoreTabbedContent,

  YoutubeVideoButton,
  PageMenu,
  SignUp,
  SeoLinks
}

tracker.milkyWhiteTracking()
vueUtil.mountComponents(components)

// run vanilla scripts here
stickyHeader()
headerDirection()
carousel()
grid()
detectImageLoaded('.c-article__main-image')
noFollowLink()
tracker.trackSimpleEventGlobal()
tracker.trackGridPageItemClick()
tracker.trackGridProductItemClick()
if (navigator.userAgent.match(/Trident\/7\./)) {
  import('@/CVI/WebCore/components/outdated-browser-popup').then((popup) => {
    popup.default()
  })
}

const enhancedEcommerceTrackItems = document.querySelectorAll('[data-track-enhanced-ecommerce]')

enhancedEcommerceTrackItems.forEach((item) => {
  tracker.enhancedEcommerceTrackerInitialize(item)
})
