<template>
  <div
    class="c-carousel"
    :style="{
      overflow: !overflowContent ? 'visible' : null
    }"
  >
    <div
      ref="carousel"
      class="swiper-container"
    >
      <div
        class="c-carousel__wrapper swiper-wrapper"
      >
        <slot />
      </div>
      <div class="c-carousel-navigation__wrapper">
        <button class="c-carousel-navigation swiper-button-prev">
          <Icon
            :icon="iconArrowLeft"
            :size="32"
            stroke="white"
          />
        </button>
        <button class="c-carousel-navigation swiper-button-next">
          <Icon
            :icon="iconArrowRight"
            :size="32"
            stroke="white"
          />
        </button>
      </div>
      <div
        v-show="$mq != 'desktop' && showPagination"
        class="c-carousel-pagination u-flex u-flex-justify-center u-flex-align-center u-mt--l@mobile"
      />
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper'
import iconArrowLeft from '@ds/svg/icons/stroke/arrow-left-1.svg'
import iconArrowRight from '@ds/svg/icons/stroke/arrow-right-1.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import getSettings from '@/CVI/WebCore/components/CarouselSettings'

export default {
  components: {
    Icon
  },
  props: {
    overflowContent: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      iconArrowLeft,
      iconArrowRight,
      showPagination: false
    }
  },
  mounted() {
    this.initCarousel()
  },
  methods: {
    initCarousel() {
      let settings = {
        spaceBetween: 12,
        touchEventsTarget: 'container',
      }
      const sharedGlobalSettings = getSettings(this.$refs.carousel, false)
      settings = {...sharedGlobalSettings, ...settings}
      if (settings.pagination) {
        this.showPagination = true
      }
      this.slider = new Swiper(this.$refs.carousel, settings)
      return this.slider
    },
    update() {
      this.slider.update()
    }
  }
}
</script>
