<template>
  <div>
    <ProductTabs
      v-if="$mq == 'desktop'"
      :model="model"
    />
    <ProductAccordion
      v-else
      :model="model"
    />
  </div>
</template>

<script>
import ProductTabs from '@/CVI/Product/components/ProductTabs.vue'
import ProductAccordion from '@/CVI/Product/components/ProductAccordion.vue'

export default {
  components: {
    ProductTabs,
    ProductAccordion
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
