var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FaceMenuTag',{attrs:{"model":_vm.model,"excluded-uid":_vm.excludedUid},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var addTag = ref.addTag;
var removeTag = ref.removeTag;
var isSelected = ref.isSelected;
return _c(_vm.model.indexed ? 'a' : 'span',{tag:"component",staticClass:"c-button c-button--small  u-text-nowrap u-flex-align-center",class:{
      'c-button--light-gray': !isSelected,
      'c-button--no-hover c-button--dark' : isSelected
    },attrs:{"href":_vm.model.indexed ? _vm.model.url : null},on:{"click":function($event){$event.preventDefault();return addTag($event)}}},[_vm._v(" "+_vm._s(_vm.model.name)+" "),(isSelected)?_c('button',{staticClass:"c-button--no-styles u-flex-inline u-flex-align-center c-facet__remove-tag",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return removeTag($event)}}},[_c('Icon',{staticClass:"u-ml--xs",attrs:{"icon":_vm.iconClose,"size":12,"fill":isSelected ? 'white' : 'dark',"hover-color":"gray"}})],1):_vm._e()])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }