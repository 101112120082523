var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('EditorialRenderless',{attrs:{"model":_vm.model},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var urlTarget = ref.urlTarget;
var urlRel = ref.urlRel;
var trackClick = ref.trackClick;
return _c('a',{ref:"link",staticClass:"c-editorial-card c-card o-border-box u-flex-align-center",class:{
      'c-card--horizontal': _vm.$mq != 'desktop'
    },attrs:{"href":_vm.model.url,"target":urlTarget,"rel":urlRel},on:{"click":trackClick}},[_c('div',{staticClass:"c-editorial-card__picture-container u-flex-shrink-0 u-mr--m u-mr--s@mobile"},[_c('Picture',{staticClass:"c-editorial-card__picture",attrs:{"src":_vm.model.image,"resizer":_vm.model.imageResizer.name,"alt":_vm.model.heading,"mobile":[80, 80],"tablet":[120, 120],"desktop":[120, 120],"wide":[300, 180],"lazy":true,"options":{ mode: 'crop'}}})],1),_c('div',{staticClass:"c-card__content"},[_c('span',{staticClass:"u-text-dark-gray u-mb--xs u-mb--xs@mobile",class:{
          'c-label--m': _vm.$mq == 'desktop',
          'c-label--s': _vm.$mq != 'desktop'
        }},[_vm._v(" "+_vm._s(_vm.model.kicker)+" ")]),_c('h5',{class:{
          'u-font-size-h5': _vm.$mq == 'desktop',
          'u-font-size-h5': _vm.$mq != 'desktop'
        }},[_vm._v(" "+_vm._s(_vm.model.heading)+" ")])]),(_vm.$mq != 'desktop')?_c('div',{staticClass:"c-card__arrow u-mb--a u-mt--a"},[_c('Icon',{attrs:{"icon":_vm.iconArrow,"size":16,"fill":"black"}})],1):_vm._e()])}}])})}
var staticRenderFns = []

export { render, staticRenderFns }