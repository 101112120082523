var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"u-flex u-flex-align-center root-link u-themeable-after-color",style:({
    '--bg-color': _vm.model.textColor || '#384241'
  })},[_c('a',{staticClass:"c-page-menu__link u-flex u-flex-align-center u-text-nowrap u-themeable-color",class:{
      'c-page-menu__link--root': _vm.$mq == 'desktop',
      'is-active': _vm.model.isRootLinkActive
    },style:({
      '--bg-color': _vm.model.textColor || '#384241'
    }),attrs:{"href":_vm.model.rootLink.url,"target":_vm.model.openInNewWindow ? '_blank' : null,"rel":_vm.model.openInNewWindow ? 'noreferrer noopener' : null}},[(!_vm.model.rootLinkIcon)?[_vm._v(" "+_vm._s(_vm.model.rootLink.text)+" ")]:_vm._e(),(_vm.model.rootLinkIcon)?_c('Picture',{attrs:{"src":_vm.model.rootLinkIcon,"resizer":_vm.model.imageResizer.name,"alt":_vm.model.rootLink.text,"desktop":[150, 40],"mobile":[150, 40],"options":{ mode : 'max'}}}):_vm._e(),_c('span',{staticClass:"c-page-menu__line-indicator u-themeable-bg",style:({
        '--bg-color': _vm.model.tabIndicatorColor || '#384241'
      })})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }