<template>
  <div>
    <div
      v-if="model.isScalable"
      class="u-flex u-flex-direction-column"
    >
      <div class="c-recipe__ingredients-input u-flex">
        <div class="c-portion-selector">
          <button
            class="c-portion-selector__button-down u-hidden-print"
            @click="changePortion(false)"
          >
            <Icon
              :icon="iconSubtract"
              :size="16"
              :stroke="'black'"
            />
          </button>
          <div
            class="c-portion-selector__input"
            :class="{
              'is-active': isDropdownShown
            }"
          >
            <Popover
              v-model="isDropdownShown"
              :placement="'bottom'"
              :auto-close="true"
              :auto-close-delay="100000"
            >
              <div
                class="c-portion-selector__input-text"
                @click="isDropdownShown = true"
              >
                {{ selectedPortionText }}
              </div>
              <select
                class="c-portion-selector__dropdown"
                @change="updatePortionValueFromSelect"
              >
                <option
                  v-for="option in model.options"
                  :key="option.value"
                  :value="option.value"
                  :selected="option.text == selectedPortionText"
                >
                  {{ option.text }}
                </option>
              </select>
              <template slot="popover">
                <ul class="c-portion-selector__options u-font-size-large">
                  <li
                    v-for="option in model.options"
                    :key="option.value"
                    class="c-portion-selector__option"
                  >
                    <a
                      href="#"
                      :class="{
                        'is-selected': option.text == selectedPortionText
                      }"
                      @click.prevent="updatePortionValue(option)"
                    >
                      {{ option.text }}</a>
                  </li>
                </ul>
              </template>
            </Popover>
          </div>
          <button
            class="c-portion-selector__button-up u-hidden-print"
            @click="changePortion(true)"
          >
            <Icon
              :icon="iconAdd"
              :size="16"
              :stroke="'black'"
            />
          </button>
        </div>
        <span
          v-if="model.portionInfoText"
          class="u-text-align--center u-font-size-large u-ml--s u-mt--s u-mb--s"
        >
          {{ model.portionInfoText }}
        </span>
      </div>
      <p
        v-if="model.warningText && !isDefaultSelected"
        class="u-m--0 u-mt--s"
      >
        {{ model.warningText }}
      </p>
    </div>
    <div v-else-if="model.notScalablePortionText">
      {{ model.notScalablePortionText }}
    </div>
  </div>
</template>

<script>
import iconAdd from '@ds/svg/icons/stroke/add.svg'
import iconSubtract from '@ds/svg/icons/stroke/subtract.svg'
import Popover from '@/CVI/WebCore/components/Popover.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

import store from '@/CVI/Recipe/store'

export default {
  components: {
    Icon,
    Popover
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      iconAdd,
      iconSubtract,
      isDropdownShown: false
    }
  },
  computed: {
    selectedPortionText() {
      const { options } = this.model
      return options.find(({ value }) => value == this.store.state.portionCount).text
    },
    isDefaultSelected() {
      return this.model.portionCount == this.store.state.portionCount
    }
  },
  created() {
    this.store.state.portionCount = this.model.portionCount
  },
  methods: {
    updatePortionValue({ value }) {
      this.isDropdownShown = false
      this.store.state.portionCount = value
      this.trackPortionsPosition(this.selectedPortionText)
    },
    updatePortionValueFromSelect(event) {
      this.store.state.portionCount = event.target.value
    },
    changePortion(increment) {
      const { options } = this.model
      const currentOptionIndex = options
        .findIndex(({ value }) => value == this.store.state.portionCount)

      if (increment) {
        if (currentOptionIndex < options.length - 1) {
          this.store.state.portionCount = options[currentOptionIndex + 1].value
        }
      } else if (currentOptionIndex > 0) {
        this.store.state.portionCount = options[currentOptionIndex - 1].value
      }
      this.trackPortionsPosition(this.selectedPortionText)
    },
    trackPortionsPosition(position) {
      tracker.trackPortions({
        event: 'portionsField',
        position
      })
    }
  }
}
</script>
