import tracker from '@/CVI/WebCore/core-tracker'
// eslint-disable-next-line import/no-cycle
import store from '@/CVI/Facet/store'

const selectTagFromAutocomplete = (filterName, searchTerm) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'dropdownTagSearch',
    currentFilters,
    filterName,
    searchTerm
  })
}

const selectFacetFromAutocomplete = (filterName, searchTerm) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'dropdownInspirationSearch',
    currentFilters,
    filterName,
    searchTerm
  })
}

const addTag = (filterName) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'selectOneFilter',
    currentFilters,
    filterName
  })
}

const removeTag = (filterName, filterPlacement) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'removeFilter',
    currentFilters,
    filterName,
    filterPlacement
  })
}

const recipeAmount = (facetRecipeCount) => {
  tracker.track({
    event: 'facetRecipeAmount',
    facetRecipeCount
  })
}

const currentTagCombination = () => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'filterCombination',
    currentFilters,
    filterNameCombination: currentFilters // mimic the old logic
  })
}

const expandMenu = (filterMenuName) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'expandFilterMenu',
    currentFilters,
    filterMenuName
  })
}

const expandSubMenu = (filterMenuName) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'expandSubFilterMenu',
    currentFilters,
    filterMenuName
  })
}

const removeAllTags = () => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'Remove All Filters',
    currentFilters,
    removeAllFilters: 'Remove All Filters'
  })
}

const selectRecipe = (recipeName) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'selectRecipe',
    currentFilters,
    recipeName
  })
}

const selectRelatedFacet = (relatedFacet) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'relatedFacets',
    currentFilters,
    relatedFacet
  })
}

const freeTextSearch = (searchTerm) => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'submitTagSearch',
    currentFilters,
    searchTerm
  })
}

const openTagMenu = () => {
  const { currentTagsAndTerms: currentFilters } = store.getters
  tracker.track({
    event: 'openFilterMenu',
    currentFilters
  })
}


export default {
  addTag,
  removeTag,
  removeAllTags,
  expandMenu,
  expandSubMenu,
  selectRelatedFacet,
  selectRecipe,
  freeTextSearch,
  currentTagCombination,
  selectTagFromAutocomplete,
  selectFacetFromAutocomplete,
  recipeAmount,
  openTagMenu
}
