<template>
  <li
    class="u-flex u-flex-align-center root-link u-themeable-after-color"
    :style="{
      '--bg-color': model.textColor || '#384241'
    }"
  >
    <a
      class="c-page-menu__link u-flex u-flex-align-center u-text-nowrap u-themeable-color"
      :style="{
        '--bg-color': model.textColor || '#384241'
      }"
      :class="{
        'c-page-menu__link--root': $mq == 'desktop',
        'is-active': model.isRootLinkActive
      }"
      :href="model.rootLink.url"
      :target="model.openInNewWindow ? '_blank' : null"
      :rel="model.openInNewWindow ? 'noreferrer noopener' : null"
    >
      <template
        v-if="!model.rootLinkIcon"
      >
        {{ model.rootLink.text }}
      </template>
      <Picture
        v-if="model.rootLinkIcon"
        :src="model.rootLinkIcon"
        :resizer="model.imageResizer.name"
        :alt="model.rootLink.text"
        :desktop="[150, 40]"
        :mobile="[150, 40]"
        :options="{ mode : 'max'}"
      />
      <span
        class="c-page-menu__line-indicator u-themeable-bg"
        :style="{
          '--bg-color': model.tabIndicatorColor || '#384241'
        }"
      />
    </a>
  </li>
</template>

<script>
import Picture from '@/CVI/WebCore/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
