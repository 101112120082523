<template>
  <div
    ref="container"
    :data-scroll-to="model.title"
    data-tracking-event-name="subnavRecipe"
  >
    <h3 class="c-recipe__instructions-label u-flex u-mb--m">
      <Icon
        :icon="iconHat"
        :size="32"
        stroke="black"
        class="u-mr--s u-hidden-print"
      />
      {{ model.title }}
    </h3>
    <div
      v-for="(section, sectionIndex) in model.sections"
      :key="sectionIndex"
    >
      <h5
        v-if="section.title"
        class="u-ml--m"
      >
        <span class="u-ml--s"> {{ section.title }} </span>
      </h5>
      <ul
        class="u-bare-list u-ml--m"
        :class="{
          'c-recipe__instructions-steps-multi': section.steps.length > 1
        }"
      >
        <CookingInstructionsLine
          v-for="(step, index) in section.steps"
          :key="index"
          :scroll-to-line="checkIfScrollToNeeded(sectionIndex, index)"
          :step="step"
          :show-checkbox="showCheckbox"
        />
      </ul>
    </div>
  </div>
</template>
<script>
import iconHat from '@ds/svg/icons/stroke/chef-gear-hat-1.svg'
import store from '@/CVI/Recipe/store'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import CookingInstructionsLine from './CookingInstructionsLine.vue'
import viewPortDetect from '@/CVI/WebCore/lib/in-viewport-detect'

export default {
  components: {
    Icon,
    CookingInstructionsLine
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      iconHat,
      locationHash: '',
      showCheckbox: false
    }
  },
  computed: {
    steps() {
      return this.model.sections.reduce((a, b) => a.concat(b.steps), [])
    }
  },
  watch: {
    steps: {
      deep: true,
      handler() {
        const instructions = this.steps.filter(inst => inst.completed).map(inst => inst.id)
        this.store.dispatch('setCompletedInstructions', { instructions })
      }
    }
  },
  created() {
    this.steps.forEach((step, index) => {
      this.$set(step, 'id', index.toString())
      this.$set(step, 'completed', this.store.state.completedInstructions.indexOf(step.id) > -1)
    })
    const { hash } = window.location

    if (hash) {
      this.locationHash = hash.replace('#step', '')
    }
  },
  mounted() {
    viewPortDetect.inViewport(this.$refs.container, () => {
      this.showCheckbox = true
    })
  },
  methods: {
    checkIfScrollToNeeded(sectionIndex, stepIndex) {
      const { locationHash } = this
      if (locationHash && locationHash == `${sectionIndex + 1}-${stepIndex + 1}`) {
        return true
      }
      return false
    }
  }
}
</script>
