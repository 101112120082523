<template>
  <div>
    <Loader
      v-if="!isReady"
      class="u-flex u-ml--a u-mr--a"
      size="large"
      color="medium-gray"
    />
    <PublisherCalendarSlider
      v-if="isReady"
      :model="model"
      :model-slider="modelSlider"
      :model-grid="modelGrid"
      :base-url="modelBaseUrl"
    />
  </div>
</template>

<script>
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    Loader,
    PublisherCalendarSlider: () => import('@/CVI/Publisher/components/CalendarSlider.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    modelSlider: {
      type: Array,
      required: true
    },
    modelGrid: {
      type: Object,
      required: true
    },
    modelBaseUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isReady: false
    }
  },
  created() {
    this.isReady = true
  }
}
</script>
