var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{staticClass:"c-page-menu__sub-list__link u-text-nounderline u-themeable-color u-themeable-hover-bg u-flex u-flex-align-center",class:{
      'has-sub-items': _vm.subMenuItems.length,
      'has-no-items': !_vm.subMenuItems.length,
      'is-expanded': _vm.isExpanded
    },style:({
      '--bg-color': _vm.model.textColor || '#384241',
      '--bg-hover': _vm.model.backgroundColor || '#fff'
    }),attrs:{"href":_vm.subMenuItem.href},on:{"click":function($event){_vm.subMenuItems.length ? _vm.onClick($event) : null}}},[_vm._v(" "+_vm._s(_vm.subMenuItem.text)+" ")]),(_vm.subMenuItems.length)?_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],staticClass:"c-page-menu__sub-list u-width-100 u-font-size-large u-themeable-bg u-themeable-darken",style:({
      '--bg-color': _vm.model.backgroundColor || '#fff'
    })},_vm._l((_vm.subMenuItems),function(subItem,index){return _c('SubMenu',{key:index,attrs:{"items":subItem.children,"sub-menu-item":subItem.link,"model":_vm.model}})}),1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }