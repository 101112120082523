var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"c-facet__search-bar",class:{
      'is-sticky': _vm.isSticky,
      'is-not-sticky': !_vm.isSticky
    }},[_c('div',{staticClass:"c-facet__search-bar-container u-flex"},[_c('button',{staticClass:" u-mr--xs",class:{
          'c-button--icon-large c-button--icon-round c-button--icon-border': _vm.$mq != 'desktop',
          'c-button c-button--dark-inverted': _vm.$mq == 'desktop',
          'c-button--dark-inverted': _vm.isSticky && _vm.$mq == 'desktop',
          'c-button--light-gray': _vm.isSticky,
          'c-button--icon-white': _vm.$mq != 'desktop'
        },on:{"click":_vm.openAllFilters}},[_c('Icon',{staticClass:"u-flex-shrink-0",class:{
            'u-mr--xs': _vm.$mq == 'desktop'
          },attrs:{"icon":_vm.iconFilters,"size":_vm.$mq == 'desktop' ? 16 : 24,"stroke":"black"}}),(_vm.$mq == 'desktop')?_c('span',[_vm._v(" "+_vm._s(_vm.filtersButtonText)+" ")]):_vm._e()],1),_c('div',{staticClass:"c-facet__search-bar-input u-flex u-flex-align-center"},[(_vm.$mq == 'desktop' && (_vm.selectedTags.length || _vm.store.state.searchTerms.length))?_c('div',{staticClass:"c-facet__search-bar-input-tags u-flex u-flex-align-center",class:{
            'u-hidden': (_vm.$mq != 'desktop' && !_vm.isSticky)
          }},[_vm._l((_vm.selectedTags),function(tag,index){return _c('TagItem',{key:tag.id,class:{
              'u-ml--xxs': index == 0,
              'u-ml--xs': index > 0
            },attrs:{"model":tag},on:{"removeTag":_vm.removeTag}})}),_vm._l((_vm.store.state.searchTerms),function(term,index){return _c('SearchTermItem',{key:term,class:{
              'u-ml--xxs': index == 0 && !_vm.selectedTags.length,
              'u-ml--xs': index > 0 || _vm.selectedTags.length
            },attrs:{"model":term},on:{"removeTerm":_vm.removeSearchTerm}})})],2):_vm._e(),(_vm.$mq != 'desktop')?[_c('div',{staticClass:"u-flex-1",on:{"click":_vm.openModal}},[_c('SearchBarAutocomplete',{staticClass:"u-mr--s",staticStyle:{"pointer-events":"none"},attrs:{"model":_vm.model,"hide-tags":true,"placeholder":_vm.mobilePlaceholderText}}),_c('input',{ref:"fakeInput",staticStyle:{"position":"absolute","left":"0","width":"100%","bottom":"0","opacity":"0","height":"0"},attrs:{"type":"text"}})],1)]:_vm._e(),(_vm.$mq =='desktop')?[_c('SearchBarAutocomplete',{class:{
              'u-ml--s': !_vm.selectedTags.length
            },attrs:{"model":_vm.model}}),(_vm.selectedTags.length || _vm.store.state.searchTerms.length)?_c('CloseButton',{staticClass:"c-facet__search-bar-clear-results u-ml--a",attrs:{"size":12,"icon-color":"white","icon-hover-color":"white","button-size":"small"},on:{"click":_vm.clearSearchResults}}):_vm._e()]:_vm._e()],2)])]),(_vm.$mq != 'desktop' && _vm.store.state.isMobileAutocompleteVisible)?_c('SearchBarMobileModal',{attrs:{"model":_vm.model},on:{"hideModal":_vm.hideModal}},[_c('SearchBarAutocomplete',{attrs:{"model":_vm.model,"focus-on-show-up":true}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }