<template>
  <FarmBooking
    :model="model"
    :slot-content="slotContent"
  />
</template>

<script>
export default {
  components: {
    FarmBooking: () => import('@/CVI/FarmBooking/components/FarmBookingApp.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    slotContent: {
      type: String,
      default: ''
    }
  }
}
</script>
