<template>
  <div class="c-mealplan-weeks">
    <MealPlan
      v-for="(mealplan, index) in model"
      :key="index"
      :week-days="weekDays"
      :model="mealplan"
      :error-title="errorTitle"
      :error-text="errorText"
      :previous-week-link-text="previousWeekLinkText"
    />
  </div>
</template>

<script>
import MealPlan from '@/CVI/Purchase/components/MealPlan/MealPlan.vue'


export default {
  components: {
    MealPlan
  },
  props: {
    model: {
      type: Array,
      required: true
    },
    previousWeekLinkText: {
      type: String,
      default: ''
    },
    weekDays: {
      type: Array,
      required: true
    },
    errorTitle: {
      type: String,
      required: true
    },
    errorText: {
      type: String,
      required: true
    }
  }
}
</script>
