<template>
  <div>
    <div
      v-show="isShown"
      class="o-content-box c-comments u-hidden-print"
    >
      <div class="c-comments__inner">
        <h3>{{ model.title }}</h3>
        <div
          ref="disqus"
          id="disqus_thread"
          :style="{
            height: isCommentsExpanded ? 'auto' : '200px'
          }"
        />
        <div
          v-if="!isCommentsExpanded && isLoaded"
          class="c-comments__reveal-button-container u-flex u-flex-justify-center u-flex-align-end"
        >
          <button
            class="c-comments__reveal-button c-button c-button--small c-button--dark"
            @click="showComments"
          >
            {{ model.seeCommentsButtonText }}
          </button>
        </div>
      </div>
    </div>
    <CookieWarning
      :model="model"
      @is-shown="checkCookie"
    />
    <div
      data-src="comments"
      :class="oneTrustCategories"
    />
  </div>
</template>

<script>
import tracker from '@/CVI/WebCore/core-tracker'
import viewPortDetect from '@/CVI/WebCore/lib/in-viewport-detect'
import CookieWarning from '@/CVI/WebCore/components/HtmlFullWidthBlock.vue'

export default {
  components: {
    CookieWarning
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isShown: false,
      isCommentsExpanded: false,
      isLoaded: false
    }
  },
  computed: {
    oneTrustCategories() {
      return `optanon-category-${Object.values(this.model.cookieConsent.oneTrustRequiredCategories).join('-')}`
    }
  },
  methods: {
    checkCookie(blockedByCookies) {
      if (!blockedByCookies) {
        this.isShown = true
        this.$nextTick(() => {
          if (this.model.lazyLoadEnabled) {
            viewPortDetect.inViewport(this.$refs.disqus, this.loadDisqus)
          } else {
            this.loadDisqus()
          }
        })
      }
    },
    loadDisqus() {
      if (!window.disqus_config) {
        return
      }

      const d = document
      const s = d.createElement('script')
      s.async = true
      s.src = `https://${disqus_shortname}.disqus.com/embed.js`
      s.setAttribute('data-timestamp', +new Date());
      s.onload = () => {
        this.isLoaded = true
      }

      (d.head || d.body).appendChild(s)
    },
    showComments() {
      this.isCommentsExpanded = true
      tracker.trackSimpleEvent({ event: 'disqusViewComments' })
    }
  }
}
</script>
