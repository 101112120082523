<template>
  <div
    ref="container"
    class="c-html-full-width-block__container"
  >
    <div
      v-if="isShown"
      class="o-content-box u-ml--a u-mr--a u-flex u-flex-align-center u-flex-direction-column"
    >
      <Icon
        :icon="cookieIcon"
        :size="64"
        fill="black"
        class="u-mb--s"
      />
      <div
        class="u-rich-text"
        v-html="model.cookieConsent.noCookieWarningText"
      />
      <ul class="u-bare-list u-text-align--center">
        <li
          v-for="(category, index) in categories"
          :key="index"
          class="u-mb--xs"
        >
          {{ category.name }}
        </li>
      </ul>
      <button
        class="c-button c-button--dark u-mt--m"
        @click="openCookie"
      >
        {{ model.cookieConsent.changeCookieSettingsText }}
      </button>
    </div>
  </div>
</template>

<script>
import cookieIcon from '@ds/svg/icons/bold/cookie-disabled.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      cookieIcon,
      oneTrustCategories: window.OptanonActiveGroups
    }
  },
  computed: {
    categories() {
      const { oneTrustRequiredCategories } = this.model.cookieConsent
      return Object.keys(oneTrustRequiredCategories)
        .map(k => ({ name: k, value: oneTrustRequiredCategories[k] }))
    },
    isShown() {
      const isInArray = ({ value }) => this.oneTrustCategories.indexOf(value) > -1
      const isShown = !this.categories.every(isInArray)
      this.$emit('is-shown', isShown)
      return isShown
    }
  },
  mounted() {
    if (!this.$refs.container.nextElementSibling) {
      return
    }
    const config = { attributes: true, childList: true, subtree: true }

    const callback = () => {
      setTimeout(() => {
        this.checkIfCategoriesUpdate()
      }, 100)
    }

    this.observer = new MutationObserver(callback)
    this.observer.observe(this.$refs.container.nextElementSibling, config)
  },
  destroyed() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },
  methods: {
    checkIfCategoriesUpdate() {
      this.oneTrustCategories = window.OptanonActiveGroups
    },
    openCookie(e) {
      e.preventDefault()
      window.reOpenCookiePopup()
    }
  }
}
</script>

<style lang="scss">
@use '@ds/styles/settings/colors.scss';

.c-html-full-width-block__container {
  background-color: colors.$light-gray;
}

.c-html-full-width-block {
  iframe[data-src] {
    display: none;
  }
}
</style>
