<template>
  <FarmBookingOverview
    :model="model"
  />
</template>

<script>
export default {
  components: {
    FarmBookingOverview: () => import('@/CVI/FarmBooking/components/FarmBookingOverview.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
