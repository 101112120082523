<template>
  <div class="c-youtube-video-button">
    <div
      ref="playerContainer"
      class="c-youtube-video-button__placeholder"
    >
      <div ref="player" />
      <template v-if="model.backgroundVideoThumbnail && model.backgroundVideoThumbnail.url">
        <Picture
          :class="thumbnailClass"
          v-if="!isPlaying"
          :src="model.backgroundVideoThumbnail"
          :resizer="model.imageResizer.name"
          :alt="model.altText"
          :mobile="mobile"
          :tablet="tablet"
          :desktop="desktop"
          :wide="wide"
          :options="{ mode: 'crop'}"
        />
      </template>
      <template v-else>
        <img
          v-if="!isPlaying"
          loading="lazy"
          class="c-youtube-video-button__preview"
          :src="`//img.youtube.com/vi/${model.videoId}/sddefault.jpg`"
          :alt="model.altText"
        >
      </template>
      <button
        v-if="isLoaded && !isPlaying"
        type="button"
        class="c-youtube-video-button__button"
        @click="playVideo"
      >
        <Icon
          :icon="iconPlay"
          :size="64"
          fill="white-70"
          hover-color="white-70"
        />
      </button>
    </div>
  </div>
</template>

<script>
import iconPlay from '@ds/svg/icons/bold/button-play-1-bold.svg'
import youtubeLoader from '@/CVI/WebCore/lib/youtube-loader'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/WebCore/store'
import viewPortDetect from '@/CVI/WebCore/lib/in-viewport-detect'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import isMobile from '@/CVI/WebCore/utils/detect-mobile'

export default {
  components: {
    Icon,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    lazy: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Array,
      required: false,
      default: null
    },
    tablet: {
      type: Array,
      required: false,
      default: null
    },
    desktop: {
      type: Array,
      required: false,
      default: null
    },
    wide: {
      type: Array,
      required: false,
      default: null
    },
    thumbnailClass: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      store,
      iconPlay,
      isPlaying: false,
      isLoaded: false
    }
  },
  watch: {
    'store.state.isYoutubeLoaded': {
      handler(isYoutubeLoaded) {
        if (isYoutubeLoaded) {
          this.isLoaded = true
          if (!this.lazy || isMobile) {
            this.initPlayer({ autoplay: false })
          }
        }
      }
    }
  },
  mounted() {
    viewPortDetect.inViewport(this.$refs.playerContainer, () => {
      youtubeLoader.loadYoutubePlayer()
    })
  },
  methods: {
    initPlayer({ autoplay }) {
      this.player = new YT.Player(this.$refs.player, {
        videoId: this.model.videoId,
        host: 'https://www.youtube-nocookie.com',
        playerVars: {
          controls: 1,
          showinfo: 0,
          rel: 0,
          playsinline: this.model.isVerticalVideo ? 0 : 1,
          autoplay
        }
      })
    },
    playVideo() {
      if (this.lazy && !isMobile) {
        this.initPlayer({ autoplay: true })
        this.isPlaying = true
      } else {
        this.player.playVideo()
        setTimeout(() => {
          this.isPlaying = true
        }, 100)
      }
    }
  }
}
</script>
