<script>
import store from '@/CVI/Facet/store'
import tracking from '@/CVI/Facet/lib/tracking'
import pageRefresh from '@/CVI/Facet/lib/page-refresh'

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    excludedUid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      store
    }
  },
  computed: {
    isSelected() {
      const { uid } = this.model
      const { tagUids } = this.store.getters
      if (tagUids.indexOf(uid) > -1) {
        return true
      }
      return false
    }
  },
  methods: {
    addTag() {
      if (!this.isSelected) {
        const { model } = this
        const { excludedUid } = this
        const tag = {
          ...model
        }
        const addItemToStart = this.$mq !== 'desktop'
        tracking.addTag(tag.name)
        store.dispatch('addTag', { tag, excludedUid, addItemToStart })
        pageRefresh.updatePage()
      }
    },
    removeTag() {
      const { uid } = this.model
      const tag = this.store.state.tags.find(t => t.uid == uid)
      tracking.removeTag(tag.name, 'Right Side Menu')
      this.store.dispatch('removeTag', { tag })
      pageRefresh.updatePage()
    }
  },
  render() {
    return this.$scopedSlots.default({
      removeTag: this.removeTag,
      addTag: this.addTag,
      isSelected: this.isSelected
    })
  }
}
</script>
