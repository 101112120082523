<template>
  <EditorialRenderless :model="model">
    <a
      ref="link"
      slot-scope="{ urlTarget, urlRel, trackClick }"
      class="c-card c-card--vertical"
      :href="model.url"
      :target="urlTarget"
      :rel="urlRel"
      @click="trackClick"
    >
      <Picture
        class="c-card__image c-card__image--large"
        :src="model.image"
        :resizer="model.imageResizer.name"
        :alt="model.heading"
        :mobile="[155, 87]"
        :tablet="[328, 185]"
        :desktop="[347, 196]"
        :wide="[424, 240]"
        :options="{ mode: 'crop'}"
      />
      <div class="c-card__content">
        <div class="u-mb--s u-mb--xxs@mobile">
          <span class="c-label--s u-text-dark-gray u-font-bold">
            {{ model.kicker }}
          </span>
        </div>
        <strong
          class="u-text-break u-mr--s u-mb--s u-mb--xxs@mobile u-font-size-h4 u-font-size-large@mobile"
        >
          {{ model.heading }}
        </strong>
      </div>
    </a>
  </EditorialRenderless>
</template>

<script>
import Picture from '@/CVI/WebCore/components/Picture.vue'
import EditorialRenderless from '@/CVI/Recipe/components/EditorialRenderless.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    EditorialRenderless,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    desktop: {
      type: Array,
      required: false,
      default: () => [76, 76]
    },
    tablet: {
      type: Array,
      required: false,
      default: () => [76, 76]
    },
    mobile: {
      type: Array,
      required: false,
      default: () => [76, 76]
    }
  },
  mounted() {
    if (this.model.trackingData) {
      tracker.trackDisplayItemImpression(this.$refs.link, this.model.trackingData)
    }
  }
}
</script>
