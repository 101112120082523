<template>
  <div class="u-flex u-flex-align-center">
    <CustomizeButton
      v-if="customizeButton.url"
      class="u-mr--xs"
      :text="customizeButton.text"
      :url="customizeButton.url"
      :tracking-category="heading"
      button-class="c-button--light-gray"
    />
    <ShareAction
      :class="{
        'u-ml--a c-button--icon c-button--icon-round c-button--icon-large c-button--icon-border': $mq != 'desktop'
      }"
      :model="shareButton"
      popover-position="top"
      popover-position-mobile="bottom"
      icon-placement="left"
      :is-button="$mq == 'desktop' ? true : false"
      :show-title="false"
      tracking-name="share mealplan"
      :url="fullUrl"
    />
    <BuyButtonMultiple
      v-if="buyButtonModel.buttons && buyButtonModel.buttons.length"
      class="u-ml--xs"
      :model="buyButtonModel"
      @click.native="onBuyButtonClick"
    />
  </div>
</template>

<script>
import BuyButtonMultiple from '@/CVI/Purchase/components/BuyButtonMultiple.vue'
import CustomizeButton from '@/CVI/Purchase/components/CustomizeButton.vue'
import ShareAction from '@/CVI/Recipe/components/ShareAction.vue'
import purchaseStore from '@/CVI/Purchase/store'

export default {
  components: {
    BuyButtonMultiple,
    CustomizeButton,
    ShareAction
  },
  props: {
    heading: {
      type: String,
      default: ''
    },
    customizeButton: {
      type: Object,
      required: true
    },
    shareButton: {
      type: Object,
      required: true
    },
    buyButtonText: {
      type: String,
      required: true
    },
    buyButtons: {
      type: Array,
      required: true
    },
    weekdays: {
      type: Array,
      required: true
    }
  },
  computed: {
    buyButtonModel() {
      const { buyButtonText: text, buyButtons: buttons } = this
      return {
        text,
        buttons: buttons.filter(buyButton => buyButton)
      }
    },
    fullUrl() {
      const { optionalUrl } = this.shareButton
      if (optionalUrl) {
        const baseUrl = `${window.location.protocol}//${window.location.hostname}`
        const port = window.location.port ? `:${window.location.port}` : ''
        return [baseUrl, port, optionalUrl].join('')
      }
      return window.location.href
    }
  },
  methods: {
    onBuyButtonClick() {
      const { weekdays, buyButtons } = this
      const mealplan = weekdays.map((day, index) => {
        const buyButton = buyButtons[index]
        if (buyButton) {
          return {
            day: day.toLowerCase(),
            uid: buyButton.uid
          }
        }
        return null
      }).filter(day => day)
      purchaseStore.dispatch('addMealplan', { mealplan })
    }
  }
}
</script>
