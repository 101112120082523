var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    c-seo-links\n    o-content-box\n    u-themeable-bg\n  ",class:{
    'u-themeable-color-dark' : _vm.model.foregroundColor == 'dark',
    'u-themeable-color-light' : _vm.model.foregroundColor == 'light',
    'u-padding--b-0' : _vm.model.removePadding
  },style:({
    '--bg-color': _vm.model.backgroundColor}),attrs:{"id":_vm.model.anchor}},[_c('span',{staticClass:"\n      c-label--m\n      u-opacity-70\n      u-mt--m\n      u-mt--m@mobile\n    "},[_vm._v(" "+_vm._s(_vm.model.kicker)+" ")]),_c('h2',{staticClass:"\n      u-font-size-h2\n      u-m--0\n      u-mt--s\n      u-mb--l\n      u-mb--l@mobile\n    ",domProps:{"innerHTML":_vm._s(_vm.model.heading)}}),_c('div',{staticClass:"\n      u-flex\n      u-flex-wrap\n      u-mb--s\n    "},_vm._l((_vm.groups),function(col,index){return _c('div',{key:index,staticClass:"c-seo-links__col u-mb--l u-mb--l@mobile",class:{'visible' : _vm.isAllGroupsVisible}},[_c('div',{staticClass:"c-seo-links__col-content"},[_c('h4',{staticClass:"u-mb--xs"},[(col.url !== null)?_c('a',{attrs:{"href":col.url}},[_vm._v(" "+_vm._s(col.headline)+" ")]):_c('span',[_vm._v(" "+_vm._s(col.headline)+" ")])]),_c('ul',{staticClass:"u-bare-list"},_vm._l((col.links),function(link){return _c('li',{key:link.url,staticClass:"u-mb--s",class:{
              'visible': col.isExpanded
            }},[_c('a',{attrs:{"href":link.url}},[_vm._v(_vm._s(link.text))])])}),0),(!col.isExpanded && col.links.length > 5)?_c('span',{staticClass:"c-seo-links__col-see-all u-text-underline",on:{"click":function($event){col.isExpanded = true}}},[_vm._v(" "+_vm._s(_vm.model.showAllLinksText)+" ("+_vm._s(col.links.length - 5)+") "),_c('Icon',{staticClass:"u-ml--xxs",attrs:{"icon":_vm.iconArrowDown,"size":12,"fill":_vm.iconColor}})],1):_vm._e()])])}),0),(_vm.$mq !== 'desktop' && !_vm.isAllGroupsVisible)?_c('div',{staticClass:"u-text-align--center"},[_c('button',{staticClass:"c-button c-button--dark c-button--small",on:{"click":function($event){_vm.isAllGroupsVisible = true}}},[_vm._v(" "+_vm._s(_vm.model.showAllGroupsText)+" ")])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }