<template>
  <div
    :class="gridClass"
  >
    <div
      v-for="(card, index) in cards"
      :key="index"
      :class="gridItemClass"
    >
      <component
        :is="getComponent(card.type)"
        :model="card"
        :mobile="[150, 120]"
        :tablet="[250, 200]"
        :desktop="[460, 250]"
        :wide="[600, 400]"
        actions-size="small"
        size="large"
      />
    </div>
  </div>
</template>

<script>
import PageCard from '@/CVI/WebCore/components/PageCard.vue'
import ProductCard from '@/CVI/Product/components/ProductCard.vue'

export default {
  components: {
    PageCard,
    ProductCard
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    components: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    gridClass() {
      const classArr = ['o-grid']
      if (this.model.columnCount == 'four') {
        classArr.push('o-grid--four-columns')
      }
      return classArr
    },
    gridItemClass() {
      return [
        'u-mb--s u-mb--xs@mobile'
      ]
    },
    cards() {
      const { cards } = this.model
      return cards
    }
  },
  methods: {
    getComponent(type) {
      const components = Object.assign({}, {
        page: PageCard,
        product: ProductCard
      }, this.components)

      return components[type.toLowerCase()]
    }
  }
}
</script>
