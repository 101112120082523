<template>
  <BuyButton
    v-if="isReady"
    v-bind="{...$props, ...$attrs}"
  />
</template>

<script>

export default {
  components: {
    BuyButton: () => import('@/CVI/Purchase/components/BuyButtonWithGuide.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showLogos: {
      type: Boolean,
      default: true
    },
    guideVisible: {
      type: Boolean,
      default: true
    },
    buttonGreen: {
      type: Boolean,
      default: false
    },
    vendorsTitle: {
      type: String,
      default: ''
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isReady: false
    }
  },
  created() {
    this.isReady = true
  }
}
</script>
