import axios from 'axios'

const language = document.documentElement.lang

export default {
  articleCards: {
    baseUrl: `/cvi/publisher/api/${language}/article-cards`,
    get({ take, skip, tagUids, excludedUids, order, cancelToken }) {
      return axios.post(this.baseUrl, {
        take, skip, tagUids, excludedUids, order
      }, {
        cancelToken: cancelToken.token
      })
    }
  }
}
