<template>
  <div ref="container">
    <div
      v-for="group in ingredientGroups"
      :key="group.title"
      class="u-mt--m"
    >
      <h5>{{ group.title }}</h5>
      <table>
        <tbody>
          <IngredientListLine
            v-for="ingredient in group.ingredients"
            :key="ingredient.formattedName"
            :title="group.title"
            :ingredient="ingredient"
            :show-checkbox="showCheckbox"
          />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import store from '@/CVI/Recipe/store'
import api from '@/CVI/Recipe/lib/api'
import IngredientListLine from '@/CVI/Recipe/components/IngredientListLine.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import viewPortDetect from '@/CVI/WebCore/lib/in-viewport-detect'

export default {
  components: {
    IngredientListLine
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      isLoading: false,
      showCheckbox: false,
      ingredientGroups: this.model.ingredientGroups
    }
  },
  computed: {
    ingredients() {
      return this.ingredientGroups.reduce((a, b) => a.concat(b.ingredients), [])
    }
  },
  watch: {
    'store.state.portionCount': {
      async handler(portionCount) {
        this.isLoading = true
        const ingredientGroups = await this.updateIngredients(portionCount)
        this.ingredientGroups = ingredientGroups
        this.isLoading = false
      }
    },
    ingredients: {
      deep: true,
      handler() {
        const ingredients = this.ingredients.filter(ing => ing.completed).map(ing => ing.id)
        this.store.dispatch('setCompletedIngredients', { ingredients })
      }
    }
  },
  created() {
    this.ingredients.forEach((ingredient, index) => {
      this.$set(ingredient, 'id', index.toString())
      this.$set(ingredient, 'completed', this.store.state.completedIngredients.indexOf(ingredient.id) > -1)
    })
  },
  mounted() {
    viewPortDetect.inViewport(this.$refs.container, () => {
      this.showCheckbox = true
    })
  },
  methods: {
    async updateIngredients(portionCount) {
      const {
        ingredientGroups
      } = (await api.ingredients.get(this.model.recipeId, portionCount)).data
      return ingredientGroups
    },
    trackProductClick(recipeProductName) {
      tracker.trackRecipeProduct({
        event: 'recipeProductClick',
        recipeProductName
      })
    }
  }
}
</script>
