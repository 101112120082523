
import utils from '@/CVI/WebCore/vue-util'
import FacetTagMenuParentItem from '@/CVI/Facet/components/FacetTagMenuParentItem.vue'
import FacetTagMenuItem from '@/CVI/Facet/components/FacetTagMenuItem.vue'
import tracking from '@/CVI/Facet/lib/tracking'
import store from '@/CVI/Facet/store'


const $root = document.querySelector('.js-facet-menu')
const $overlay = $root?.querySelector('.c-facet-tag-menu__overlay')

const mountTags = ($accordionItem) => {
  utils.mountChildComponents({
    FacetTagMenuParentItem,
    FacetTagMenuItem
  }, null, $accordionItem)
}

const initOverlayClick = () => {
  $overlay.addEventListener('click', (e) => {
    e.preventDefault()
    store.dispatch('setAllFiltersVisibility', { value: false })
  })
}

const initMainAccordion = () => {
  const $accordionTitles = Array.from($root.querySelectorAll('.c-accordion-item__title')).map((node) => {
    return {
      node,
      initialized: false
    }
  })
  const toggleAccordionContent = (item) => {
    const openedClassName = 'is-opened'
    if (item.node.classList.contains(openedClassName)) {
      item.node.classList.remove(openedClassName)
      item.node.nextElementSibling.classList.remove(openedClassName)
    } else {
      $accordionTitles.forEach(({ node }) => {
        node.classList.remove(openedClassName)
        node.nextElementSibling.classList.remove(openedClassName)
      })
      const title = item.node.innerText.replace(item.node.children[0]?.innerText, '').trim()
      tracking.expandMenu(title)

      if (!item.initialized) {
        item.initialized = true
        mountTags(item.node.parentNode)
        requestAnimationFrame(() => {
          item.node.classList.add(openedClassName)
          item.node.nextElementSibling.classList.add(openedClassName)
          item.node.scrollIntoViewIfNeeded()
        })
      } else {
        item.node.classList.add(openedClassName)
        item.node.nextElementSibling.classList.add(openedClassName)
        item.node.scrollIntoViewIfNeeded()
      }
    }
  }

  $accordionTitles.forEach((item) => {
    item.node.addEventListener('click', (e) => {
      e.preventDefault()
      toggleAccordionContent(item)
    })
  })
}


export default {
  initMainAccordion,
  initOverlayClick
}
