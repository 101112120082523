<template>
  <x-modal
    :name="name"
    :duration="300"
    :adaptive="true"
    :width="width"
    transition="modal-slide-up"
    :pivot-y="0"
    :max-width="maxWidth"
    :height="fullHeight ? '100%' : 'auto'"
    :shift-y="0"
    :shift-x="0"
    @closed="close"
    @opened="disableScroll"
  >
    <div
      class="v--modal__wrapper u-flex u-flex-direction-column u-flex-align-center"
    >
      <button
        class="v--modal__close c-button-close--s u-ml--a u-flex-shrink-0"
        :class="{
          'u-mb--s': isTitleCentered
        }"
        @click="close"
      >
        <Icon
          :icon="iconClose"
          stroke="black"
          hover-color="medium-gray"
        />
      </button>
      <h3
        v-if="title.length"
        class="u-font-size-h3 "
        :class="{
          'u-text-align--center': isTitleCentered,
          'u-text-align--left': !isTitleCentered
        }"
      >
        {{ title }}
      </h3>
      <div class="v--modal__content">
        <div
          v-if="onlyScrollInModal"
          ref="container"
          class="v--modal__content--scroll"
        >
          <slot />
        </div>
        <template v-else>
          <slot />
        </template>
      </div>
    </div>
  </x-modal>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import iconClose from '@ds/svg/icons/stroke/close.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    isTitleCentered: {
      type: Boolean,
      default: true
    },
    onlyScrollInModal: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 640
    },
    tabletWidth: {
      type: Number,
      default: 480
    },
    fullHeight: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: Math.random().toString(36).slice(2, 5),
      iconClose
    }
  },
  computed: {
    maxWidth() {
      if (this.$mq == 'mobile') {
        return window.innerWidth
      }

      if (this.$mq == 'tablet') {
        return this.tabletWidth
      }

      return this.width
    }
  },
  watch: {
    value(show) {
      if (show) {
        this.$modal.show(this.name)
      } else {
        this.$modal.hide(this.name)
      }
    }
  },

  beforeDestroy() {
    if (this.onlyScrollInModal) {
      enablePageScroll(this.$refs.container)
    }
  },

  methods: {
    disableScroll() {
      if (this.onlyScrollInModal) {
        disablePageScroll(this.$refs.container)
      }
    },
    close() {
      this.$emit('input', false)
      if (this.onlyScrollInModal) {
        enablePageScroll(this.$refs.container)
      }
    }
  }
}
</script>
