<script>
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    urlTarget() {
      return this.model.openInNewWindow ? '_blank' : null
    },
    urlRel() {
      return this.model.openInNewWindow ? 'noopener noreferrer' : null
    }
  },
  methods: {
    trackClick(event) {
      tracker.trackDisplayItemImpressionClick(event, this.model.trackingData, this.model.url)
    }
  },
  render() {
    return this.$scopedSlots.default({
      urlTarget: this.urlTarget,
      urlRel: this.urlRel,
      trackClick: this.trackClick
    })
  }
}
</script>
