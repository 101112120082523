var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"menuWrapper",staticClass:"c-page-menu__wrapper",class:{
    'has-global-navigation-hidden': _vm.isGlobalHeaderHidden,
    'is-brand-page': _vm.isBrandPage,
    'no-brand-page': !_vm.isBrandPage
  }},[_c('div',{ref:"menu",staticClass:"\n      c-page-menu\n      u-flex\n      u-flex-align-center\n      u-hidden-print\n      u-themeable-bg\n    ",class:{
      'is-sticky': _vm.isSticky,
      'has-border': _vm.model.showBottomLineBorder
    },style:({
      '--bg-color': _vm.model.backgroundColor || '#fff'
    })},[(_vm.menuItems)?_c('ul',{directives:[{name:"start-with-html",rawName:"v-start-with-html",value:(_vm.menuItems),expression:"menuItems"}],key:"menuItems",staticClass:"c-page-menu__list u-flex u-m--0 u-ml--a u-mr--a u-bare-list"},[(_vm.model.rootLink.text)?_c('RootLink',{attrs:{"model":_vm.model}}):_vm._e()],1):_vm._e()]),(_vm.dropdownContent)?_c('ul',{staticClass:"c-page-menu__sub-list c-page-menu__sub-list--top-level u-font-size-large u-themeable-bg u-themeable-darken",class:{
      'is-parent-sticky': _vm.isSticky,
      'u-width-100': _vm.$mq !== 'desktop'
    },style:({
      '--bg-color': _vm.model.backgroundColor || '#fff',
      left: (_vm.leftOffset + "px")
    })},_vm._l((_vm.dropdownContent),function(subMenu,index){return _c('SubItems',{key:index,attrs:{"model":_vm.model,"items":subMenu.children,"sub-menu-item":subMenu.link}})}),1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.dropdownContent)?_c('span',{staticClass:"c-page-menu__overlay",on:{"click":_vm.hideDropdownMenu}}):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }