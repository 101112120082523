import './scss/recipe.scss'
import './scss/recipe-full-width-teaser.scss'
import './icons'

import vueUtil from '@/CVI/WebCore/vue-util'

import RecipeActionBar from '@/CVI/Recipe/components/ActionBarWrapper.vue'
import RecipeCookingInstructions from '@/CVI/Recipe/components/CookingInstructions.vue'
import RecipeIngredientList from '@/CVI/Recipe/components/IngredientList.vue'
import RecipeMobileSubMenu from '@/CVI/Recipe/components/MobileSubMenu.vue'
import RecipePortionSelector from '@/CVI/Recipe/components/PortionSelector.vue'
import RecipeRating from '@/CVI/Recipe/components/Rating.vue'
import RecipeSaveAction from '@/CVI/Recipe/components/SaveAction.vue'
import recipeStore from '@/CVI/Recipe/store'
import RecipeEditorialCard from '@/CVI/Recipe/components/EditorialCard.vue'
import RecipeStickyBar from '@/CVI/Recipe/components/StickyBar.vue'
import RecipeBuyButton from '@/CVI/Recipe/components/RecipeBuyButton.vue'
import RecipeGrid from '@/CVI/Recipe/components/RecipeGrid.vue'

// CODE FOR OPTIMIZE
// eslint-disable-next-line
window.__recipeStore = recipeStore

const components = {
  RecipeActionBar,
  RecipeCookingInstructions,
  RecipeIngredientList,
  RecipeMobileSubMenu,
  RecipePortionSelector,
  RecipeRating,
  RecipeEditorialCard,
  RecipeSaveAction,
  RecipeStickyBar,
  RecipeBuyButton,
  RecipeGrid
}

vueUtil.mountComponents(components)
