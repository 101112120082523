<template>
  <a
    :href="`${model.url}#where-to-buy`"
    class="c-button c-product-card__buy-button c-button--small c-button--light-gray u-mt--s"
    @click="trackProductClick"
  >
    <Icon
      class="u-mr--xs"
      :icon="iconCart"
      :size="16"
      stroke="black"
    />
    {{ model.whereToBuyButtonText }}
  </a>
</template>

<script>
import iconCart from '@ds/svg/icons/stroke/shopping-basket-1.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconCart
    }
  },
  methods: {
    trackProductClick() {
      tracker.track({
        event: 'whereToBuyButton',
        productName: this.model.heading
      })
    }
  }
}
</script>
