<template>
  <li>
    <a
      class="c-page-menu__sub-list__link u-text-nounderline u-themeable-color u-themeable-hover-bg u-flex u-flex-align-center"
      :href="subMenuItem.href"
      :style="{
        '--bg-color': model.textColor || '#384241',
        '--bg-hover': model.backgroundColor || '#fff'
      }"
      :class="{
        'has-sub-items': subMenuItems.length,
        'has-no-items': !subMenuItems.length,
        'is-expanded': isExpanded
      }"
      @click="subMenuItems.length ? onClick($event) : null"
    >
      {{ subMenuItem.text }}
    </a>
    <ul
      v-if="subMenuItems.length"
      v-show="isExpanded"
      class="c-page-menu__sub-list u-width-100 u-font-size-large u-themeable-bg u-themeable-darken"
      :style="{
        '--bg-color': model.backgroundColor || '#fff'
      }"
    >
      <SubMenu
        v-for="(subItem, index) in subMenuItems"
        :key="index"
        :items="subItem.children"
        :sub-menu-item="subItem.link"
        :model="model"
      />
    </ul>
  </li>
</template>

<script>
export default {
  name: 'SubMenu',
  props: {
    model: {
      type: Object,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    subMenuItem: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isExpanded: false
    }
  },
  computed: {
    subMenuItems() {
      const { items, subMenuItem } = this
      if (!items.length) {
        return []
      }

      return [{
        link: { ...subMenuItem },
        children: []
      }, ...items]
    }
  },
  methods: {
    onClick(e) {
      e.preventDefault()
      this.isExpanded = !this.isExpanded
    }
  }
}
</script>
