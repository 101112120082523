import imagesLoaded from 'imagesloaded'

const detectImageLoaded = (containerClass) => {
  const container = document.querySelectorAll(containerClass)
  imagesLoaded(container, ({ images }) => {
    images.forEach(({ img }) => {
      img.classList.add('loaded')
    })
  })
}

export default detectImageLoaded
