import './scss/product.scss'
import ProductGrid from '@/CVI/Product/components/ProductGrid.vue'
import ProductAdimoButton from '@/CVI/Product/components/AdimoButton.vue'
import ProductDetails from '@/CVI/Product/components/ProductDetails.vue'
import ProductWhereToBuy from '@/CVI/Product/components/WhereToBuy/WhereToBuyLoader.vue'
import ProductWhereToBuyButton from '@/CVI/Product/components/WhereToBuy/WhereToBuyButton.vue'

import vueUtil from '@/CVI/WebCore/vue-util'

const components = {
  ProductAdimoButton,
  ProductDetails,
  ProductGrid,
  ProductWhereToBuy,
  ProductWhereToBuyButton
}

vueUtil.mountComponents(components)
