import './scss/article.scss'
import './scss/article-teaser.scss'
import './scss/calendar-slider.scss'
import vueUtil from '@/CVI/WebCore/vue-util'
import articleImageAnimation from '@/CVI/WebCore/lib/article-image-animation'
import PublisherArticleGrid from '@/CVI/Publisher/components/ArticleGrid.vue'
import PublisherCalendarSlider from '@/CVI/Publisher/components/PublisherCalendarSlider.vue'

window.addEventListener('load', () => {
  articleImageAnimation.makeVisibleInViewport('.c-article__body picture')
  articleImageAnimation.makeVisibleInViewport('.c-article__body blockquote')
  articleImageAnimation.makeVisibleInViewport('.c-article-video')
})

const components = {
  PublisherArticleGrid,
  PublisherCalendarSlider
}

vueUtil.mountComponents(components)
