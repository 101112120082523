<template>
  <div>
    <keep-alive>
      <ShoppingCart
        v-if="isVisible"
        :model="model"
      />
    </keep-alive>
    <Loader
      v-if="!isVisible"
      class="c-shopping-cart__loader u-ml--a u-mr--a"
      size="large"
      color="medium-gray"
    />
  </div>
</template>

<script>
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    Loader,
    ShoppingCart: () => import('@/CVI/Purchase/components/ShoppingCart.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  created() {
    this.isVisible = true
  }
}
</script>
