<template>
  <button
    class="u-flex-shrink-0 u-hidden-print"
    :class="{
      'c-button--icon-large c-button--icon-round c-button--icon-green': $mq != 'desktop',
      'c-button c-button--green': $mq == 'desktop'
    }"
    @click="onButtonClick"
  >
    <Icon
      :class="{
        'u-mr--xs': $mq == 'desktop'
      }"
      :icon="iconCart"
      :size="iconSize"
      :stroke="'white'"
    />
    <span v-if="$mq == 'desktop'">{{ model.text }}</span>
  </button>
</template>

<script>
import iconCart from '@ds/svg/icons/stroke/shopping-basket-1.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Purchase/store'
import webcoreStore from '@/CVI/WebCore/store'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconCart,
      store,
      webcoreStore
    }
  },
  computed: {
    buttons() {
      return this.model.buttons.map(({ uid, portionCount }) => ({ uid, portionCount }))
    },
    products() {
      return this.model.buttons.map(({ trackingData }) => ({ ...trackingData }))
    },
    iconSize() {
      return this.$mq == 'desktop' ? 16 : 24
    }
  },
  methods: {
    onButtonClick() {
      const { buttons, products } = this
      this.store.dispatch('addRecipesToCart', buttons)
      this.webcoreStore.state.isShoppingPanelVisible = true
      if (products) {
        tracker.track({
          event: 'checkout',
          eventCategory: 'Ecommerce',
          eventAction: 'Add to basket',
          ecommerce: {
            checkout: {
              actionField: {
                step: 1
              },
              products
            }
          }
        })
      }
    }
  }
}
</script>
