<template>
  <a
    :class="[buttonClass]"
    class="c-button"
    :href="url"
    @click="onClick"
  >
    <Icon
      class="u-mr--xs"
      :icon="iconCalendar"
      :size="16"
      stroke="black"
    />
    {{ text }}
  </a>
</template>
<script>
import iconCalendar from '@ds/svg/icons/stroke/calendar.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    text: {
      type: String,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    trackingCategory: {
      type: String,
      required: true
    },
    buttonClass: {
      type: String,
      default: 'c-button--light  c-button--icon-hover-dark'
    }
  },
  data() {
    return {
      iconCalendar
    }
  },
  methods: {
    onClick() {
      const { trackingCategory: mealPlanCategory } = this

      tracker.track({
        mealPlanCategory,
        event: 'customizeButton',
        customizeButton: mealPlanCategory
      })
    }
  }
}
</script>
