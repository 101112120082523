<template>
  <div
    :class="{
      'c-facet__related-facets--show-all': isOpened
    }"
    class="c-facet__related-facets u-mt--m@mobile u-mb--m@mobile"
  >
    <h5 class="c-label--m u-text-dark-gray u-mb--s">
      {{ model.relatedFacetsTitle }}
    </h5>
    <div class="u-flex u-flex-wrap">
      <a
        v-for="(facet, index) in model.relatedFacets"
        :key="index"
        class="c-facet__related-facets-item c-button c-button--small c-button--light-gray-solid u-mr--xs u-mb--xs u-text-align--left"
        :href="facet.url"
        @click.prevent="onClick(facet)"
      >
        {{ facet.title }}
      </a>
    </div>
    <button
      v-if="model.relatedFacets.length > 4"
      class="c-button--no-styles u-cursor-pointer u-mt--xxs u-font-size-medium"
      @click="toggleShowAll"
    >
      <template v-if="isOpened">
        {{ model.seeLessText }}
      </template>
      <template v-else>
        {{ model.seeMoreText }}
      </template>
      <Icon
        :icon="isOpened ? arrowUp : arrowDown"
        :size="8"
        fill="black"
        class="u-ml--xxs"
      />
    </button>
  </div>
</template>

<script>
import arrowDown from '@ds/svg/icons/bold/arrow-down-1-bold.svg'
import arrowUp from '@ds/svg/icons/bold/arrow-up-1-bold.svg'
import store from '@/CVI/Facet/store'
import tracking from '@/CVI/Facet/lib/tracking'
import pageRefresh from '@/CVI/Facet/lib/page-refresh'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      arrowDown,
      arrowUp,
      store,
      isOpened: false
    }
  },
  methods: {
    async onClick(facet) {
      tracking.selectRelatedFacet(facet.title)
      store.dispatch('showLoader')
      store.dispatch('setTags', { tags: facet.fullContentCriteriaTags })
      pageRefresh.updatePage()
    },
    toggleShowAll() {
      this.isOpened = !this.isOpened
    }
  }
}
</script>
