<template>
  <a
    :href="url"
    class="c-share-popup__button c-icon-32 u-flex u-flex-justify-center u-flex-align-center"
    :style="{ backgroundColor }"
    target="_blank"
    rel="noopener noreferrer"
    @click="onClick"
  >
    <Icon
      :icon="icon"
      :size="16"
      fill="white"
    />
  </a>
</template>

<script>
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    url: {
      type: String,
      required: true
    },
    icon: {
      type: Object,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
