<template>
  <div>
    <ProductWhereToBuy
      v-if="isVisible"
      v-bind="{...$props, ...$attrs}"
    />
    <Loader
      v-if="!isVisible"
      class="u-ml--a u-mr--a"
      size="large"
      color="medium-gray"
    />
  </div>
</template>

<script>
import Loader from '@/CVI/WebCore/components/Loader.vue'

export default {
  components: {
    Loader,
    ProductWhereToBuy: () => import('@/CVI/Product/components/WhereToBuy/WhereToBuy.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isVisible: false
    }
  },
  created() {
    this.isVisible = true
  }
}
</script>
