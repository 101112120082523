<template>
  <FaceMenuTag :model="model">
    <div
      ref="container"
      slot-scope="{ addTag, removeTag, isSelected }"
      class="u-flex u-flex-align-baseline"
    >
      <component
        :is="model.indexed ? 'a' : 'span'"
        class="c-button c-button--small u-text-nowrap"
        :href="model.indexed ? model.url : null"
        :class="{
          'c-button--no-hover c-button--dark': isSelected,
          'c-button--light': !isSelected
        }"
        :style="{
          'pointer-events': disabled ? 'none' : null
        }"
        :disabled="disabled"
        @click.prevent="addTag"
      >
        {{ model.name }}
        <span
          v-if="isSelected"
          class="c-button--no-styles c-facet__remove-tag u-flex-inline u-flex-align-center"
          @click.stop.prevent="removeTag"
        >
          <Icon
            class="u-ml--xs"
            :icon="iconClose"
            :size="12"
            :fill="isSelected ? 'white' : 'dark'"
            hover-color="gray"
          />
        </span>
      </component>
      <button
        v-if="model.children.length"
        class="c-facet-tag-menu__children-tag-toggler c-button--no-styles u-flex-inline u-font-size-medium u-ml--s"
        :class="{
          'is-opened': isOpened
        }"
        @click="toggleChildren"
      >
        <template v-if="isOpened">
          {{ store.state.translations.showLessText }}
        </template>
        <template v-else>
          {{ store.state.translations.showMoreText }}
        </template>
      </button>
    </div>
  </FaceMenuTag>
</template>

<script>
import iconClose from '@ds/svg/icons/bold/close-bold.svg'
import store from '@/CVI/Facet/store'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import FaceMenuTag from '@/CVI/Facet/components/FacetTagMenuTag.vue'
import tracking from '@/CVI/Facet/lib/tracking'


export default {
  components: {
    Icon,
    FaceMenuTag
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      isOpened: false,
      iconClose
    }
  },
  computed: {
    disabled() {
      const { children } = this.model
      if (!children.length) {
        return false
      }

      const { tags } = this.store.state
      const tagUids = tags.map(tag => tag.uid)
      const childrenTagUids = children.map(tag => tag.uid)

      return tagUids.filter(uid => childrenTagUids.includes(uid)).length > 0
    }
  },
  methods: {
    toggleChildren() {
      this.isOpened = !this.isOpened
      const childrenContainer = this.$refs.container.nextElementSibling

      if (this.isOpened) {
        tracking.expandSubMenu(this.model.name)
        childrenContainer.classList.add('is-opened')
      } else {
        childrenContainer.classList.remove('is-opened')
      }
    }
  }
}
</script>
