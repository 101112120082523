<template>
  <div
    class="c-tab"
    :class="{
      'c-tab--is-active': value == id
    }"
  >
    <a
      :href="href"
      @click.prevent="onTabClick()"
    >
      {{ title }}
    </a>
  </div>
</template>

<script>
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    href: {
      type: String,
      required: true
    }
  },
  computed: {
    id() {
      return this.href.substr(1)
    }
  },
  methods: {
    onTabClick() {
      this.$emit('input', this.id)
      tracker.trackTabItemClick({
        event: 'tabClick',
        tabName: this.$el.innerText
      })
    }
  }
}
</script>
