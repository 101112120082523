<template>
  <div />
</template>
<script>
import store from '@/CVI/Facet/store'
import tracking from '@/CVI/WebCore/core-tracker'
import pageRefresh from '@/CVI/Facet/lib/page-refresh'

export default {
  data() {
    return {
      store
    }
  },
  watch: {
    'store.state.tags': {
      handler() {
        this.emptyServerRenderedContainer()
      }
    },
    'store.state.searchTerms': {
      handler() {
        this.emptyServerRenderedContainer()
      }
    }
  },
  mounted() {
    this.$blocksContainer = document.querySelector('.js-facet-blocks-container')
    this.addRelatedFacetEvents()
  },
  methods: {
    emptyServerRenderedContainer() {
      this.$blocksContainer.remove()
    },
    async addRelatedFacetEvents() {
      this.$blocksContainer.querySelectorAll('.c-seo-links__col a').forEach((item) => {
        item.addEventListener('click', (e) => {
          e.preventDefault()
          const tags = JSON.parse(item.dataset.model)
          const title = item.innerText
          tracking.track({
            event: 'Populära Recept',
            popularRecipe: title
          })
          this.store.dispatch('showLoader')
          this.store.dispatch('setTags', { tags })
          pageRefresh.updatePage()
        })
      })
    }
  }
}
</script>
