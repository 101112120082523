<template>
  <EditorialRenderless :model="model">
    <a
      ref="link"
      slot-scope="{ urlTarget, urlRel, trackClick }"
      class="c-editorial-card c-card o-border-box u-flex-align-center"
      :href="model.url"
      :target="urlTarget"
      :rel="urlRel"
      :class="{
        'c-card--horizontal': $mq != 'desktop'
      }"
      @click="trackClick"
    >
      <div class="c-editorial-card__picture-container u-flex-shrink-0 u-mr--m u-mr--s@mobile">
        <Picture
          class="c-editorial-card__picture"
          :src="model.image"
          :resizer="model.imageResizer.name"
          :alt="model.heading"
          :mobile="[80, 80]"
          :tablet="[120, 120]"
          :desktop="[120, 120]"
          :wide="[300, 180]"
          :lazy="true"
          :options="{ mode: 'crop'}"
        />
      </div>

      <div class="c-card__content">
        <span
          class="u-text-dark-gray u-mb--xs u-mb--xs@mobile"
          :class="{
            'c-label--m': $mq == 'desktop',
            'c-label--s': $mq != 'desktop'
          }"
        >
          {{ model.kicker }}
        </span>
        <h5
          :class="{
            'u-font-size-h5': $mq == 'desktop',
            'u-font-size-h5': $mq != 'desktop'
          }"
        >
          {{ model.heading }}
        </h5>
      </div>
      <div
        v-if="$mq != 'desktop'"
        class="c-card__arrow u-mb--a u-mt--a"
      >
        <Icon
          :icon="iconArrow"
          :size="16"
          fill="black"
        />
      </div>
    </a>
  </EditorialRenderless>
</template>

<script>
import iconArrow from '@ds/svg/icons/bold/arrow-right-1-bold.svg'

import EditorialRenderless from '@/CVI/Recipe/components/EditorialRenderless.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import Picture from '@/CVI/WebCore/components/Picture.vue'
import tracker from '@/CVI/WebCore/core-tracker'


export default {
  components: {
    EditorialRenderless,
    Icon,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconArrow
    }
  },
  mounted() {
    if (this.model.trackingData) {
      tracker.trackDisplayItemImpression(this.$refs.link, this.model.trackingData)
    }
  }
}
</script>
