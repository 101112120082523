<template>
  <span
    ref="radio"
    :class="rootClass"
    :role="!value ? 'button' : ''"
    tabindex="0"
    @click="toggleComplete"
  >
    <span :class="cssLabelClass" />
    <span :class="cssIconClass">
      <Icon
        :icon="iconCheck"
        :fill="alternative ? 'black' : 'white'"
      />
    </span>
  </span>
</template>

<script>
import iconCheck from '@ds/svg/icons/bold/check-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    appearance: {
      type: String,
      default: 'radio'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    alternative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconCheck,
      isAnimated: false
    }
  },
  computed: {
    cssClass() {
      return `c-${this.appearance}`
    },
    rootClass() {
      return [
        `${this.cssClass}`,
        `${this.cssClass}--success`,
        `${this.cssClass}--inline`,
        this.value ? 'is-active' : '',
        this.isAnimated ? 'is-animated' : '',
        this.disabled ? 'is-disabled' : '',
        this.alternative ? 'is-dark' : ''
      ]
    },
    cssLabelClass() {
      return [`${this.cssClass}__label`]
    },
    cssIconClass() {
      return [`${this.cssClass}-icon`]
    }
  },
  methods: {
    toggleComplete() {
      this.$emit('input', !this.value)
      this.isAnimated = true
      setTimeout(() => {
        this.isAnimated = false
      }, 100)
    }
  }
}

</script>
