<template>
  <Accordion
    class="c-accordion--default"
  >
    <AccordionItem
      v-for="(item, itemIndex) in faqs"
      :id="generateId(item.question)"
      :key="itemIndex"
      :update-url="true"
      v-model="activeAccordionItem"
      :tracking-type="trackingType"
    >
      <template v-slot:title>
        <span>{{ item.question }}</span>
      </template>
      <div class="u-rich-text u-font-size-large" v-html="item.answer" />
    </AccordionItem>
  </Accordion>
</template>

<script>
import Accordion from '@/CVI/WebCore/components/Accordion.vue'
import AccordionItem from '@/CVI/WebCore/components/AccordionItem.vue'
import slugify from '@/CVI/WebCore/lib/slugify'

export default {
  components: {
    Accordion,
    AccordionItem
  },
  props: {
    faqs: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeAccordionItem: '',
      trackingType: 'faqItem'
    }
  },
  methods: {
    generateId(title) {
      return slugify(title)
    }
  }
}
</script>
