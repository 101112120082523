<template>
  <span v-if="total > 0">({{ total }})</span>
</template>

<script>
import store from '@/CVI/Facet/store'

export default {
  props: {
    model: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      store
    }
  },
  computed: {
    total() {
      const uids = this.model
      const { tags } = this.store.state
      const tagUids = tags.map(tag => tag.uid)
      return tagUids.filter(uid => uids.includes(uid)).length
    }
  }
}
</script>
