<template>
  <div>
    <div
      v-if="$mq == 'desktop'"
      :id="model.anchor"
      class="c-tabbed-content"
      :class="{'u-padding--b-0': model.removePadding}"
    >
      <div
        class="o-content-box--side u-flex u-flex-justify-center u-themeable-bg"
        :class="foreground == 'light' ? 'u-themeable-color-light' : 'u-themeable-color-dark'"
        :style="{'--bg-color': background}"
      >
        <Tab
          v-for="(tab, index) in model.teasers"
          :key="index"
          v-model="activeTab"
          :href="`#${index.toString()}`"
          class="u-mr--l"
          :title="tab.name"
        />
      </div>
      <div
        class="c-tab-content u-themeable-bg"
        :class="foreground == 'light' ? 'u-themeable-color-light' : 'u-themeable-color-dark'"
        :style="{'--bg-color': background}"
      >
        <TabContent
          v-for="(content, index) in model.teasers"
          :id="index.toString()"
          :key="index"
          :visible="activeTab === index.toString()"
        >
          <div
            v-html="content.renderedHtml"
          />
        </TabContent>
      </div>
    </div>
    <Accordion
      v-else
      class="c-accordion--content"
    >
      <AccordionItem
        v-for="(item, index) in model.teasers"
        :id="index.toString()"
        :key="index"
        v-model="activeAccordionItem"
        class="u-themeable-bg"
        :class="item.foregroundColor == 'light' ? 'u-themeable-color-light' : 'u-themeable-color-dark'"
        :style="{'--bg-color': item.backgroundColor}"
        :tracking-type="trackingType"
      >
        <template v-slot:title>
          <span class="u-font-size-xlarge u-font-bold">{{ item.name }}</span>
        </template>
        <div
          class="c-tab-content u-themeable-bg"
          :class="item.foregroundColor == 'light' ? 'u-themeable-color-light' : 'u-themeable-color-dark'"
          :style="{'--bg-color': item.backgroundColor}"
          v-html="item.renderedHtml"
        />
      </AccordionItem>
    </Accordion>
  </div>
</template>

<script>
import Tab from '@/CVI/WebCore/components/Tab.vue'
import TabContent from '@/CVI/WebCore/components/TabContent.vue'
import Accordion from '@/CVI/WebCore/components/Accordion.vue'
import AccordionItem from '@/CVI/WebCore/components/AccordionItem.vue'

export default {
  components: {
    Tab,
    TabContent,
    Accordion,
    AccordionItem
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeAccordionItem: '0',
      trackingType: '',
      activeTab: '0'
    }
  },
  computed: {
    background() {
      return this.model.teasers[parseInt(this.activeTab, 0)].backgroundColor
    },
    foreground() {
      return this.model.teasers[parseInt(this.activeTab, 0)].foregroundColor
    }
  }
}
</script>
<style lang="scss">
.c-tabbed-content {
  .c-tab {
    > a {
      color: inherit;
    }
  }
  .c-tab-content {
    border-top: 0;
  }
}
</style>
