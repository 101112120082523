<template>
  <div
    class="u-font-size-xlarge"
  >
    <h3 class="u-mb--m u-mb--l@mobile">
      {{ model.heading }}
    </h3>
    <div class="c-product-tab__nutrition u-flex u-flex-direction-column@mobile">
      <div class="c-product-tab__nutrition-column u-flex-shrink-0">
        <NutritionSummary
          v-if="!model.hideNutritionSummary"
          :model="model.summary"
        />
        <ProductIngredients
          :model="model.ingredients"
        />
      </div>
      <ProductFacts
        :model="model.facts"
      />
    </div>
  </div>
</template>

<script>
import ProductIngredients from '@/CVI/Product/components/ProductIngredients.vue'
import NutritionSummary from '@/CVI/Product/components/NutritionSummary.vue'
import ProductFacts from '@/CVI/Product/components/ProductFacts.vue'

export default {
  components: {
    ProductIngredients,
    NutritionSummary,
    ProductFacts
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
