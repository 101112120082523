<template>
  <ActionBar
    v-if="isReady"
    :model="model"
    :show-titles="showTitles"
    :popover-position="popoverPosition"
    :popover-position-mobile="popoverPositionMobile"
    :buy-button="buyButton"
  />
</template>

<script>

export default {
  components: {
    ActionBar: () => import('@/CVI/Recipe/components/ActionBar.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showTitles: {
      type: Boolean,
      default: false
    },
    popoverPosition: {
      type: String,
      default: 'bottom'
    },
    popoverPositionMobile: {
      type: String,
      default: 'bottom'
    },
    buyButton: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isReady: false
    }
  },
  created() {
    this.isReady = true
  }
}
</script>
