<template>
  <div>
    <div
      class="c-facet__search-bar"
      :class="{
        'is-sticky': isSticky,
        'is-not-sticky': !isSticky
      }"
    >
      <div class="c-facet__search-bar-container u-flex">
        <button
          class=" u-mr--xs"
          :class="{
            'c-button--icon-large c-button--icon-round c-button--icon-border': $mq != 'desktop',
            'c-button c-button--dark-inverted': $mq == 'desktop',
            'c-button--dark-inverted': isSticky && $mq == 'desktop',
            'c-button--light-gray': isSticky,
            'c-button--icon-white': $mq != 'desktop'
          }"
          @click="openAllFilters"
        >
          <Icon
            class="u-flex-shrink-0"
            :class="{
              'u-mr--xs': $mq == 'desktop'
            }"
            :icon="iconFilters"
            :size="$mq == 'desktop' ? 16 : 24"
            stroke="black"
          />
          <span v-if="$mq == 'desktop'">
            {{ filtersButtonText }}
          </span>
        </button>
        <div class="c-facet__search-bar-input u-flex u-flex-align-center">
          <div
            v-if="$mq == 'desktop' && (selectedTags.length || store.state.searchTerms.length)"
            class="c-facet__search-bar-input-tags u-flex u-flex-align-center"
            :class="{
              'u-hidden': ($mq != 'desktop' && !isSticky)
            }"
          >
            <TagItem
              v-for="(tag, index) in selectedTags"
              :key="tag.id"
              :model="tag"
              :class="{
                'u-ml--xxs': index == 0,
                'u-ml--xs': index > 0
              }"
              @removeTag="removeTag"
            />

            <SearchTermItem
              v-for="(term, index) in store.state.searchTerms"
              :key="term"
              :model="term"
              :class="{
                'u-ml--xxs': index == 0 && !selectedTags.length,
                'u-ml--xs': index > 0 || selectedTags.length
              }"
              @removeTerm="removeSearchTerm"
            />
          </div>

          <template v-if="$mq != 'desktop'">
            <div
              class="u-flex-1"
              @click="openModal"
            >
              <SearchBarAutocomplete
                class="u-mr--s"
                style="pointer-events: none;"
                :model="model"
                :hide-tags="true"
                :placeholder="mobilePlaceholderText"
              />
              <input
                ref="fakeInput"
                type="text"
                style="position: absolute;left: 0;width: 100%;bottom: 0;opacity: 0;height: 0;"
              >
            </div>
          </template>

          <template v-if="$mq =='desktop'">
            <SearchBarAutocomplete
              :class="{
                'u-ml--s': !selectedTags.length
              }"
              :model="model"
            />
            <CloseButton
              v-if="selectedTags.length || store.state.searchTerms.length"
              class="c-facet__search-bar-clear-results u-ml--a"
              :size="12"
              icon-color="white"
              icon-hover-color="white"
              button-size="small"
              @click="clearSearchResults"
            />
          </template>
        </div>
      </div>
    </div>
    <SearchBarMobileModal
      v-if="$mq != 'desktop' && store.state.isMobileAutocompleteVisible"
      :model="model"
      @hideModal="hideModal"
    >
      <SearchBarAutocomplete
        :model="model"
        :focus-on-show-up="true"
      />
    </SearchBarMobileModal>
  </div>
</template>

<script>
import iconFilters from '@ds/svg/icons/stroke/filters.svg'
import iconSearchBold from '@ds/svg/icons/bold/search-alternate-bold.svg'
import iconClose from '@ds/svg/icons/bold/close-bold.svg'

import store from '@/CVI/Facet/store'
import updateUrl from '@/CVI/Facet/lib/update-url'
import CloseButton from '@/CVI/WebCore/components/CloseButton.vue'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import TagItem from '@/CVI/Facet/components/TagItem.vue'
import SearchTermItem from '@/CVI/Facet/components/SearchTermItem.vue'
import tracking from '@/CVI/Facet/lib/tracking'
import pageRefresh from '@/CVI/Facet/lib/page-refresh'


export default {
  components: {
    CloseButton,
    Icon,
    SearchBarAutocomplete: () => import('@/CVI/Facet/components/SearchBarAutocomplete.vue'),
    SearchBarMobileModal: () => import('@/CVI/Facet/components/SearchBarMobileModal.vue'),
    SearchTermItem,
    TagItem
  },
  props: {
    filtersButtonText: {
      type: String,
      default: ''
    },
    isSticky: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconFilters,
      iconClose,
      selectedTags: this.model.selectedTags,
      iconSearchBold,
      store
    }
  },
  computed: {
    mobilePlaceholderText() {
      const tags = this.store.state.tags.map(tag => tag.name)
      const { searchTerms } = this.store.state
      return [...tags, ...searchTerms].join(' + ')
    }
  },
  watch: {
    'store.state.tags': {
      handler(tags) {
        this.selectedTags = tags
      }
    }
  },
  mounted() {
    this.setInitialTags()
    this.setInitialSearchTerms()
    this.setInitialFacetSlug()
  },
  methods: {
    setInitialTags() {
      const { selectedTags: tags } = this.model
      this.store.dispatch('setTags', { tags })
    },
    setInitialFacetSlug() {
      const { facetSlug } = this.model
      this.store.dispatch('setFacetSlug', { facetSlug })
    },
    setInitialSearchTerms() {
      const url = new URL(window.location.href)
      const searchParams = new URLSearchParams(url.search.slice(1))
      const searchTerms = searchParams.get('search')
      if (searchTerms) {
        this.store.dispatch('setSearchTerms', { searchTerms: searchTerms.split('+') })
      }
    },
    async clearSearchResults() {
      tracking.removeAllTags()
      this.store.dispatch('showLoader')
      this.store.dispatch('resetCards')
      updateUrl.reload()
    },
    openModal() {
      this.$refs.fakeInput.focus() // fix for IOS
      this.store.dispatch('setMobileAutoCompleteVisibility', { value: true })
    },
    hideModal() {
      this.store.dispatch('setMobileAutoCompleteVisibility', { value: false })
    },
    openAllFilters() {
      this.store.dispatch('setAllFiltersVisibility', { value: true })
    },
    async removeSearchTerm(searchTerm) {
      tracking.removeTag(searchTerm, 'Top Menu')
      this.store.dispatch('removeSearchTerm', { searchTerm })
      pageRefresh.updatePage()
    },
    async removeTag(tag) {
      tracking.removeTag(tag.name, 'Top Menu')
      this.store.dispatch('removeTag', { tag })
      pageRefresh.updatePage()
    }
  }
}
</script>
