<template>
  <FaceMenuTag
    :model="model"
    :excluded-uid="excludedUid"
  >
    <component
      :is="model.indexed ? 'a' : 'span'"
      slot-scope="{ addTag, removeTag, isSelected }"
      class="c-button c-button--small  u-text-nowrap u-flex-align-center"
      :class="{
        'c-button--light-gray': !isSelected,
        'c-button--no-hover c-button--dark' : isSelected
      }"
      :href="model.indexed ? model.url : null"
      @click.prevent="addTag"
    >
      {{ model.name }}
      <button
        v-if="isSelected"
        class="c-button--no-styles u-flex-inline u-flex-align-center c-facet__remove-tag"
        @click.stop.prevent="removeTag"
      >
        <Icon
          class="u-ml--xs"
          :icon="iconClose"
          :size="12"
          :fill="isSelected ? 'white' : 'dark'"
          hover-color="gray"
        />
      </button>
    </component>
  </FaceMenuTag>
</template>

<script>
import iconClose from '@ds/svg/icons/bold/close-bold.svg'
import store from '@/CVI/Facet/store'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import FaceMenuTag from '@/CVI/Facet/components/FacetTagMenuTag.vue'


export default {
  components: {
    Icon,
    FaceMenuTag
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    excludedUid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      store,
      iconClose
    }
  }
}
</script>
