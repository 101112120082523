<template>
  <div>
    <h4>{{ model.heading }}</h4>
    <div class="c-product-tab__nutrition-summary u-flex u-mb--m">
      <div class="c-product-tab__nutrition-summary-wrapper u-flex u-text-align--center u-font-bold">
        <div class="c-product-tab__nutrition-summary-col">
          <strong class="u-mb--s">{{ model.energyText }}</strong>
          <span class="u-mb--xs">{{ model.energyKiloJouleValue }}</span>
          <span>{{ model.energyCalorieValue }}</span>
        </div>
        <NutritionSummaryFact
          :model="model.fat"
        />
        <NutritionSummaryFact
          :model="model.saturatedFat"
        />
        <NutritionSummaryFact
          :model="model.sugar"
        />
      </div>
    </div>
  </div>
</template>

<script>
import NutritionSummaryFact from '@/CVI/Product/components/NutritionSummaryFact.vue'

export default {
  components: {
    NutritionSummaryFact
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
