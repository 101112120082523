<template>
  <div>
    <div class="u-flex u-mb--s u-mr--l">
      <!-- Facebook -->
      <SharePopupButton
        :url="`http://www.facebook.com/share.php?u=${shareUrl}`"
        :icon="iconFacebook"
        :background-color="facebookColor"
        class="u-mr--xs"
        @click="trackClick('facebook')"
      />
      <!-- Twitter -->
      <SharePopupButton
        :url="`http://twitter.com/share?url=${shareUrl}&text=${model.name}`"
        :icon="iconTwitter"
        :background-color="twitterColor"
        class="u-mr--xs"
        @click="trackClick('twitter')"
      />
      <!-- LinkedIn -->
      <SharePopupButton
        :url="`http://www.linkedin.com/shareArticle?mini=true&url=${shareUrl}`"
        :icon="iconLinkedIn"
        :background-color="linkedInColor"
        @click="trackClick('linkedin')"
      />
    </div>
    <div class="u-flex u-flex-direction-column">
      <span class="u-mb--xs">{{ model.copyLinkText }}</span>
      <input
        ref="input"
        class="c-text-input c-share-popup__input"
        :class="{
          'c-text-input--s': $mq == 'desktop'
        }"
        type="text"
        :value="shareUrl"
        @focus="copyText"
      >
    </div>
  </div>
</template>

<script>
import iconFacebook from '@ds/svg/icons/bold/social-media-facebook-bold.svg'
import iconTwitter from '@ds/svg/icons/bold/social-media-twitter-bold.svg'
import iconLinkedIn from '@ds/svg/icons/bold/professional-network-linkedin-bold.svg'
import tracker from '@/CVI/WebCore/core-tracker'

import SharePopupButton from './SharePopupButton.vue'

export default {
  components: {
    SharePopupButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    trackingPosition: {
      type: String,
      default: ''
    },
    trackingName: {
      type: String,
      default: 'sharePosition'
    },
    trackingObj: {
      type: Object,
      default: null
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconFacebook,
      iconTwitter,
      iconLinkedIn,
      twitterColor: '#1FA1F1',
      facebookColor: '#3D5B98',
      linkedInColor: '#0278B5',
      shareUrl: this.url || window.location.href
    }
  },
  mounted() {
    document.body.parentElement.addEventListener('urlchanged', () => {
      this.updateUrl()
    })
    window.addEventListener('hashchange', () => {
      this.updateUrl()
    })
  },
  methods: {
    copyText(event) {
      event.currentTarget.select()
      document.execCommand('copy')
    },
    trackClick(network) {
      let trackingObj = {
        event: this.trackingName,
        network
      }
      const trackingObjProp = this.trackingObj

      if (trackingObjProp) {
        trackingObj = { ...trackingObjProp }
      }

      if (this.trackingName == 'sharePosition') {
        trackingObj.position = this.trackingPosition
      }

      tracker.track(trackingObj)
    },
    updateUrl() {
      this.shareUrl = window.location.href
    },
    blurInput() {
      this.$refs.input.blur()
    }
  }
}

</script>
