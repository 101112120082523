<template>
  <tr
    :class="{
      'c-recipe__ingredients-selected-line u-text-medium-gray': ingredient.completed
    }"
  >
    <th
      class=" u-width-70"
    >
      <div class="u-flex u-flex-align-center">
        <Checkbox
          v-if="showCheckbox"
          v-model="ingredient.completed"
          class="u-hidden-print"
        />
        <template v-if="ingredient.isArlaProduct">
          <a
            :href="ingredient.pageUrl"
            class="c-recipe__ingredients-product-link u-flex-inline u-flex-align-center"
            @click="trackProductClick(ingredient.formattedName)"
          >
            <picture
              class="c-recipe__ingredients-product u-flex-shrink-0 u-flex-align-center u-mr--s u-hidden-print"
            >
              <img
                :src="ingredient.thumbnailUrl"
                :srcset="`${ingredient.thumbnail2xUrl} 2x`"
                :alt="ingredient.formattedName"
              >
            </picture>
            <span>{{ ingredient.formattedName }}</span>
          </a>
        </template>
        <template v-else>
          <span>{{ ingredient.formattedName }}</span>
        </template>
      </div>
    </th>
    <td class="u-text-align--right">
      {{ ingredient.formattedAmount }}
    </td>
  </tr>
</template>

<script>
import Checkbox from '@/CVI/WebCore/components/Checkbox.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Checkbox
  },
  props: {
    ingredient: {
      type: Object,
      required: true
    },
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'ingredient.completed': {
      handler(isCompleted) {
        if (isCompleted) {
          tracker.trackIngredientMark({
            event: 'checkIngredient',
            ingredientName: this.ingredient.formattedName
          })
        }
      }
    }
  },
  methods: {
    trackProductClick(recipeProductName) {
      tracker.trackRecipeProduct({
        event: 'recipeProductClick',
        recipeProductName
      })
    }
  }
}
</script>
