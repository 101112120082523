<template>
  <div
    ref="bar"
    class="c-recipe__sticky-bar u-width-100 u-hidden-print"
  >
    <div
      v-if="visible"
      class="u-flex u-flex-justify-sides"
    >
      <RecipeCard
        :model="card"
        layout="horizontal"
        :arrow="false"
        :recipe-preview="true"
        :show-rating-count="false"
        :mobile="[51, 51]"
      />
      <div class="c-recipe__sticky-bar__button u-flex">
        <BuyButtonWithText
          v-if="$mq == 'desktop' && buyButton != null"
          class="u-flex-self-center"
          :model="buyButton"
          :show-logos="false"
          :guide-visible="false"
        />
        <BuyButton
          v-if="$mq != 'desktop' && buyButton != null"
          :model="buyButton.button"
        />
        <ActionBar
          :model="actionBar"
          :show-titles="false"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RecipeCard from '@/CVI/Recipe/components/RecipeCard.vue'
import BuyButton from '@/CVI/Purchase/components/BuyButton.vue'
import BuyButtonWithText from '@/CVI/Purchase/components/BuyButtonWithText.vue'
import ActionBar from '@/CVI/Recipe/components/ActionBarWrapper.vue'
import recipeStore from '@/CVI/Recipe/store'

export default {
  components: {
    RecipeCard,
    BuyButtonWithText,
    BuyButton,
    ActionBar
  },
  props: {
    card: {
      type: Object,
      default: () => {}
    },
    buyButton: {
      type: Object,
      default: () => {}
    },
    actionBar: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      visible: false,
      topOffset: null,
      stickyPoint: null,
      recipeStore,
      buyButtonModel: this.buyButton
    }
  },
  watch: {
    'recipeStore.state.portionCount': {
      handler(value) {
        this.buyButtonModel.portionCount = value
      }
    }
  },
  mounted() {
    const stickyPointEl = document.querySelector('.c-recipe__details')

    this.topOffset = stickyPointEl.clientHeight / 2
    this.stickyPoint = stickyPointEl.offsetTop + stickyPointEl.clientHeight
    window.addEventListener('scroll', this.setBarPosition)
  },
  methods: {
    setBarPosition() {
      const scrollTop = window.scrollY

      if (scrollTop > this.topOffset) {
        this.visible = true
      }
      if (scrollTop > this.stickyPoint) {
        this.$refs.bar.classList.add('visible')
      } else {
        this.$refs.bar.classList.remove('visible')
      }
    }
  }
}
</script>
