<template>
  <div>
    <Grid
      :model="gridModel"
    />
    <div class="u-flex u-flex-justify-center">
      <button
        v-if="showMoreButton"
        ref="button"
        class="c-button c-button--light u-mt--s"
        :class="{
          'is-loading': isLoading
        }"
        @click="loadMore"
      >
        <span class="c-button__text">
          {{ model.seeAllButtonText }}
        </span>
        <Loader
          v-if="isLoading"
          class="c-button__loader"
        />
      </button>
    </div>
  </div>
</template>
<script>
import api from '@/CVI/WebCore/lib/api'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import Grid from '@/CVI/WebCore/components/Grid.vue'

export default {
  components: {
    Grid,
    Loader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    brandUids: {
      type: Array,
      default: () => []
    },
    tagUids: {
      type: Array,
      default: () => []
    },
    brandIds: {
      type: Array,
      default: () => []
    },
    categoryIds: {
      type: Array,
      default: () => []
    },
    isManualProducts: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      cards: this.model.cards,
      take: this.model.pageSize,
      skip: this.model.pageSize,
      apiUrl: 'productCards',
      showMoreButton: this.model.pageSize < this.model.totalCount,
      isLoading: false
    }
  },
  computed: {
    gridModel() {
      const { cards } = this
      const { columnCount } = this.model

      return {
        cards,
        columnCount
      }
    }
  },
  methods: {
    async loadMore(event) {
      if (!this.apiUrl) {
        return
      }

      if (!(event instanceof KeyboardEvent)) {
        this.$refs.button.blur()
      }

      this.isLoading = true
      const {
        take, skip, categoryIds, brandIds, tagUids, brandUids
      } = this
      const { data } = await api[this.apiUrl].get({
        take,
        skip,
        categoryIds,
        tagUids,
        brandUids,
        brandIds
      })
      this.skip += take
      if (this.skip >= data.total) {
        this.showMoreButton = false
      }
      this.cards = this.cards.concat(data.cards)
      this.isLoading = false
    }
  }
}
</script>
