<template>
  <Popover
    v-model="isPopoverVisible"
    :color="'black'"
    class="c-action-bar__item"
    :placement="$mq == 'desktop' ? popoverPosition : popoverPositionMobile"
  >
    <ActionBarItem
      v-if="!isButton"
      :text="model.label"
      :icon="iconShare"
      :icon-active="iconShare"
      :show-title="showTitle"
      :icon-hover-color="'medium-gray'"
      :icon-placement="iconPlacement"
      @click="togglePopover"
    />
    <ShareButton
      v-else
      :text="model.label"
      @click="togglePopover"
    />

    <template slot="popover">
      <SharePopup
        ref="popup"
        :model="model"
        :tracking-name="trackingName"
        :tracking-obj="trackingObj"
        :tracking-position="showTitle ? 'sticky' : 'top'"
        :url="url"
      />
    </template>
  </Popover>
</template>

<script>
import iconShare from '@ds/svg/icons/stroke/share-1.svg'

import Popover from '@/CVI/WebCore/components/Popover.vue'
import ActionBarItem from './ActionBarItem.vue'
import SharePopup from './SharePopup.vue'
import ShareButton from './ShareButton.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Popover,
    ActionBarItem,
    SharePopup,
    ShareButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    popoverPosition: {
      type: String,
      default: 'bottom'
    },
    popoverPositionMobile: {
      type: String,
      default: 'bottom'
    },
    trackingName: {
      type: String,
      default: ''
    },
    trackingObj: {
      type: Object,
      default: null
    },
    iconPlacement: {
      type: String,
      default: 'right'
    },
    isButton: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      iconShare,
      isPopoverVisible: false,
      supportWebShareApi: window.navigator.share
    }
  },
  mounted() {
    window.addEventListener('scroll', () => {
      this.isPopoverVisible = false
    })
  },
  methods: {
    togglePopover() {
      if (this.supportWebShareApi && this.$mq != 'desktop') {
        this.share()
      } else {
        this.isPopoverVisible = !this.isPopoverVisible
        if (this.isPopoverVisible) {
          this.trackData(this.trackingName)
        }
      }
    },
    async share() {
      const { url } = this
      const { title } = window.document
      if (window.navigator.share) {
        await window.navigator.share({
          title,
          url
        })
        this.trackData(this.trackingName)
      }
    },
    trackData(event) {
      const trackingObj = {
        event
      }
      if (event == 'sharePosition') {
        trackingObj.position = this.showTitle ? 'sticky' : 'top'
      }
      tracker.track(trackingObj)
    }
  }
}
</script>
