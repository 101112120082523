import Swiper from 'swiper'
import getSettings from '@/CVI/WebCore/components/CarouselSettings'

const carousel = () => {
  const slideshow = document.querySelectorAll('.c-carousel__container--wrapper')

  slideshow.forEach((element) => {
    const cssSizes = element.classList.contains('c-carousel--css-sizes')

    let settings = {
      slidesPerView: 'auto',
      spaceBetween: 16,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    }

    if (!cssSizes) {
      const sharedGlobalSettings = getSettings(element, !element.classList.contains('four-column'))
      const breakPointSettings = {
        slidesPerView: 1,
        centerInsufficientSlides: true
      }
      settings = { ...sharedGlobalSettings, ...settings, ...breakPointSettings }
    }

    if (settings.pagination) {
      const pagination = element.querySelector('.c-carousel-pagination')
      if (pagination) {
        pagination.classList.remove('u-hidden')
      }
    }

    const slider = new Swiper(element, settings)
    return slider
  })
}

export default carousel
