<template>
  <button
    class="c-button c-button--light-gray"
    @click.prevent="onClick"
  >
    <Icon
      :size="16"
      stroke="black"
      :icon="iconShare"
      class="u-mr--xs"
    />
    {{ text }}
  </button>
</template>

<script>
import iconShare from '@ds/svg/icons/stroke/share-1.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      iconShare
    }
  },
  methods: {
    onClick() {
      this.$emit('click')
    }
  }
}
</script>
