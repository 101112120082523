import storeFactory from '@/CVI/WebCore/lib/store-export-factory'
import api from '@/CVI/Facet/lib/api'
import webCoreApi from '@/CVI/WebCore/lib/api'
import tracking from '@/CVI/Facet/lib/tracking'

let loadCancelToken = null

const store = {
  state: {
    blocksRenderedFromServer: true,
    gridCards: [],
    skip: 0,
    totalCount: 0,
    sortOrder: '',
    loading: false,
    loadingMoreRecipes: false,
    allFiltersVisible: false,
    isMobileAutocompleteVisible: false,
    heroData: null,
    tags: [],
    searchTerms: [],
    facetSlug: '',
    hasMoreResults: true,
    translations: {},
    pageSize: 0
  },
  getters: {
    skip(state) {
      return state.skip
    },
    tagUids(state) {
      return state.tags.map(({ uid }) => uid)
    },
    currentSlugs(state) {
      return state.tags.map(({ slug }) => slug)
    },
    currentTagsAndTerms(state) {
      const { tags, searchTerms } = state
      const tagNames = tags.map(tag => tag.name)
      return tagNames.concat(searchTerms).sort().join(',')
    },
    isFacet0(state) {
      const { tags, searchTerms } = state
      return !tags.length && !searchTerms.length
    }
  },
  actions: {
    setBlocksRendered({ state }, { value }) {
      state.blocksRenderedFromServer = value
    },
    setTranslations({ state }, { translations }) {
      state.translations = {
        ...translations
      }
    },
    setMobileAutoCompleteVisibility({ state }, { value }) {
      state.isMobileAutocompleteVisible = value
    },
    setStateFromUrl({ state }, { state: stateFromUrl }) {
      const {
        tags, searchTerms, sortOrder, heroData, facetSlug
      } = stateFromUrl
      state.facetSlug = facetSlug
      state.tags = tags
      state.searchTerms = searchTerms
      state.sortOrder = sortOrder
      state.heroData = heroData
    },
    setSortOrder({ state }, { sortOrder }) {
      state.sortOrder = sortOrder
    },
    setTags({ state }, { tags }) {
      state.tags = tags
    },
    setSearchTerms({ state }, { searchTerms }) {
      state.searchTerms = searchTerms
    },
    setFacetSlug({ state }, { facetSlug }) {
      state.facetSlug = facetSlug
    },
    updateSearchTerms({ state }, { searchTerm }) {
      state.searchTerms.push(searchTerm)
    },
    removeSearchTerm({ state }, { searchTerm }) {
      state.searchTerms = state.searchTerms.filter(term => term != searchTerm)
    },
    setPageSize({ state }, { pageSize }) {
      state.pageSize = pageSize
    },
    addTag({ state }, { tag, addItemToStart, excludedUid = '' }) {
      const tags = state.tags.filter(({ uid }) => uid != excludedUid)
      if (addItemToStart) {
        tags.unshift(tag)
      } else {
        tags.push(tag)
      }
      state.tags = tags
    },
    removeTag({ state }, { tag }) {
      state.tags = state.tags.filter(t => t != tag)
    },
    showLoader({ state }) {
      state.loading = true
    },
    hideLoader({ state }) {
      state.loading = false
    },
    updateSkip({ state }, { skip }) {
      if (state.totalCount > state.skip + skip) {
        state.skip += skip
      } else {
        state.hasMoreResults = false
      }
    },
    setTotalCount({ state }, { count }) {
      state.totalCount = count
    },
    resetCards({ state }) {
      state.gridCards = []
      state.skip = 0
    },
    async loadCards({ state }, { tagUids }) {
      const { sortOrder, searchTerms } = state
      state.hasMoreResults = true

      if (loadCancelToken) {
        loadCancelToken.cancel()
      }

      loadCancelToken = webCoreApi.createCancelToken()

      const {
        data: {
          gridCards, heroData, metaDataPageTitle, facetSlug
        }
      } = await api.recipes.get({
        cancelToken: loadCancelToken,
        sortOrder,
        searchTerms,
        tags: tagUids
      })

      state.facetSlug = facetSlug
      state.gridCards = gridCards.items
      state.heroData = heroData
      document.title = metaDataPageTitle
      tracking.recipeAmount(gridCards.totalCount)

      const { pageSize } = state

      this.setTotalCount({ state }, { count: gridCards.totalCount })
      this.updateSkip({ state }, { skip: pageSize })
    },
    async getMoreRecipes({ state }, { skip, tagUids }) {
      const { sortOrder, searchTerms, pageSize } = state
      state.hasMoreResults = true

      if (loadCancelToken) {
        loadCancelToken.cancel()
      }
      state.loadingMoreRecipes = true
      loadCancelToken = webCoreApi.createCancelToken()
      try {
        const {
          data: { gridCards }
        } = await api.recipes.get({
          cancelToken: loadCancelToken,
          skip,
          sortOrder,
          searchTerms,
          tags: tagUids
        })

        this.updateSkip({ state }, { skip: pageSize })
        state.gridCards = [...state.gridCards, ...gridCards.items]
        state.loadingMoreRecipes = false
      } catch (e) {
        state.loadingMoreRecipes = false
      }
    },
    setAllFiltersVisibility({ state }, { value }) {
      state.allFiltersVisible = value
      if (value) {
        tracking.openTagMenu()
      }
    }
  }
}

export default storeFactory.create(store)
