<template>
  <div class="c-accordion">
    <slot />
  </div>
</template>
<script>
export default {

}
</script>
