<template>
  <RecipeCard
    :mobile="[155, 87]"
    :tablet="[328, 185]"
    :desktop="[347, 196]"
    :wide="[424, 240]"
    :model="model"
    size="large"
    :show-image-load-state="true"
    @click.native="onRecipeClick"
  />
</template>

<script>
import RecipeCard from '@/CVI/Recipe/components/RecipeCard.vue'
import tracking from '@/CVI/Facet/lib/tracking'

export default {
  components: {
    RecipeCard
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  methods: {
    onRecipeClick() {
      tracking.selectRecipe(this.model.title)
    }
  }
}
</script>
