const headerScrollDirection = () => {
  let directionTimeout = null
  const header = document.querySelector('.c-header')
  const getScrollDistance = (function () {
    let prevScrollY = 0

    return function (scrollY) {
      const scrollDistance = scrollY - prevScrollY
      prevScrollY = scrollY
      return scrollDistance
    }
  }())

  const getScrollDirection = function (scrollDistance) {
    return scrollDistance >= 0 ? 'down' : 'up'
  }


  const detectDirection = function () {
    const distance = getScrollDistance(window.scrollY)
    const direction = getScrollDirection(distance)
    if (directionTimeout) {
      clearTimeout(directionTimeout)
      directionTimeout = null
    }
    directionTimeout = setTimeout(() => {
      if (direction == 'up') {
        header.classList.remove('direction-down')
        header.classList.add('direction-up')
      } else {
        header.classList.add('direction-down')
        header.classList.remove('direction-up')
      }
    }, 20)
  }

  const initDirection = function () {
    detectDirection()
    window.addEventListener('scroll', detectDirection)
  }

  if (header) {
    window.addEventListener('load', initDirection)
  }
}

export default headerScrollDirection
