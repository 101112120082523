const options = {
  root: null,
  rootMargin: '0px 0px 0px 0px'
}

export default {
  inViewport(domElement, callback, viewportOptions = options) {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (callback) {
            callback()
          }
          observer.unobserve(entry.target)
        }
      })
    }, viewportOptions)
    observer.observe(domElement)
  }
}
