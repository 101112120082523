import store from '@/CVI/Facet/store'
import updateUrl from '@/CVI/Facet/lib/update-url'

const updatePageWithoutRefresh = async () => {
  const { tagUids } = store.getters
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
  store.dispatch('resetCards')
  store.dispatch('showLoader')
  await store.dispatch('loadCards', { tagUids })
  const { state } = store
  updateUrl.save(state)
  store.dispatch('hideLoader')
}

export default {
  updatePage() {
    const { isFacet0 } = store.getters
    if (isFacet0) {
      store.dispatch('showLoader')
      store.dispatch('resetCards')
      updateUrl.reload()
    } else {
      updatePageWithoutRefresh()
    }
  }
}
