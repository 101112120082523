<template>
  <FoodMovers
    v-if="isReady"
    v-bind="{...$props, ...$attrs}"
  />
</template>

<script>

export default {
  components: {
    FoodMovers: () => import('@/CVI/FoodMovers/components/FoodMovers.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isReady: false
    }
  },
  created() {
    this.isReady = true
  }
}
</script>
