import tracking from '@/CVI/WebCore/core-tracker'

const init = () => {
  const $root = document.querySelector('.js-facet-zero-container')

  if (!$root) {
    return
  }

  const promoTracking = (target, event) => {
    const root = target.closest('.c-recipe-full-width-teaser__row')
    const linkClickType = root.classList.contains('c-recipe-full-width-teaser--image-left') ? 'Right' : 'Left'
    const linkClickPromoFacet = root?.querySelector('h2').innerText

    tracking.track({
      event,
      linkClickType,
      linkClickPromoFacet
    })
  }

  const facetPromoBuy = (target) => {
    promoTracking(target, 'addToCartFacet')
  }

  const facetPromo = (target) => {
    promoTracking(target, 'topFacetPromoBlock')
  }

  const getTopFacetDetails = (target) => {
    const selectedBlockRecipe = target.closest('.c-card')?.dataset.trackingTitle
    const linkClickTopFacetBlock = target.closest('.c-facet-tag-related-recipes').querySelector('h2').innerText

    return {
      selectedBlockRecipe,
      linkClickTopFacetBlock
    }
  }

  const topFacetRecipeCarousel = (target) => {
    const { selectedBlockRecipe, linkClickTopFacetBlock } = getTopFacetDetails(target)
    tracking.track({
      event: 'topFacetRecipeCarousel',
      selectedBlockRecipe,
      linkClickTopFacetBlock
    })
  }

  const topFacetBlockRecipe = (target) => {
    const { linkClickTopFacetBlock } = getTopFacetDetails(target)
    tracking.track({
      event: 'Top Facet block recipe',
      linkClickTopFacetBlock,
      lickClickType: 'Button'
    })
  }

  const topFacetAddToBasket = (target) => {
    const { selectedBlockRecipe, linkClickTopFacetBlock } = getTopFacetDetails(target)
    tracking.track({
      event: 'addToBagFromRecipeFacetBlock',
      linkClickTopFacetBlock,
      selectedBlockRecipe,
      lickClickType: 'Button'
    })
  }

  const topFacetAddToFavorites = (target) => {
    const { selectedBlockRecipe, linkClickTopFacetBlock } = getTopFacetDetails(target)
    tracking.track({
      event: 'addToFavoritesFromRecipeFacetBlock',
      linkClickTopFacetBlock,
      selectedBlockRecipe
    })
  }

  const topFacetRelatedFacet = (target) => {
    const linkClickRelatedTopFacetBlock = target.innerText
    const { linkClickTopFacetBlock } = getTopFacetDetails(target)

    tracking.track({
      event: 'Top Facet block related facet',
      linkClickTopFacetBlock,
      linkClickRelatedTopFacetBlock
    })
  }


  $root.addEventListener('click', (e) => {
    // recipe full width teaser
    const facetPromoBuyButton = e.target.closest('.js-recipe-full-width-add-to-basket')
    const facetPromoLink = e.target.closest('.js-recipe-full-width-see-more')

    // tag related recipes

    const facetRecipeCarousel = e.target.closest('.c-card')
    const facetRecipeCarouselTitle = e.target.closest('.js-facet-tag-related-recipes-title')

    const facetRecipeRelatedFacet = e.target.closest('.c-facet-tag-related-recipes__facet')

    if (facetPromoBuyButton) {
      facetPromoBuy(facetPromoBuyButton)
    }
    if (facetPromoLink) {
      facetPromo(facetPromoLink)
    }


    if (facetRecipeCarousel) {
      topFacetRecipeCarousel(facetRecipeCarousel)
    }
    if (facetRecipeCarouselTitle) {
      topFacetBlockRecipe(facetRecipeCarouselTitle)
    }

    if (facetRecipeRelatedFacet) {
      topFacetRelatedFacet(facetRecipeRelatedFacet)
    }
  })

  document.body.addEventListener('buyButtonClicked', (event) => {
    const facetRecipeCarouselBuy = event.detail.target.closest('.c-facet-tag-related-recipes')
    if (facetRecipeCarouselBuy) {
      topFacetAddToBasket(event.detail.target)
    }
  })
  document.body.addEventListener('saveActionClicked', (event) => {
    const facetRecipeAddToFavorites = event.detail.target.closest('.c-facet-tag-related-recipes')
    if (facetRecipeAddToFavorites) {
      topFacetAddToFavorites(event.detail.target)
    }
  })
}

export default init
