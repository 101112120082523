<template>
  <div class="u-flex u-flex-shrink-0">
    <CloseButton
      class="u-ml--a"
      @click="close"
    />
  </div>
</template>

<script>
import store from '@/CVI/Facet/store'
import CloseButton from '@/CVI/WebCore/components/CloseButton.vue'


export default {
  components: {
    CloseButton
  },
  methods: {
    close() {
      store.dispatch('setAllFiltersVisibility', { value: false })
    }
  }
}
</script>
