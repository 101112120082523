import viewPortDetect from '@/CVI/WebCore/lib/in-viewport-detect'

export default {
  makeVisibleInViewport(selector) {
    const articleBodyImages = document.querySelectorAll(selector)

    articleBodyImages.forEach((item) => {
      viewPortDetect.inViewport(item, () => {
        item.classList.add('visible')
      })
    })
  }
}
