<template>
  <div
    ref="menuWrapper"
    class="c-page-menu__wrapper"
    :class="{
      'has-global-navigation-hidden': isGlobalHeaderHidden,
      'is-brand-page': isBrandPage,
      'no-brand-page': !isBrandPage
    }"
  >
    <div
      ref="menu"
      class="
        c-page-menu
        u-flex
        u-flex-align-center
        u-hidden-print
        u-themeable-bg
      "
      :class="{
        'is-sticky': isSticky,
        'has-border': model.showBottomLineBorder
      }"
      :style="{
        '--bg-color': model.backgroundColor || '#fff'
      }"
    >
      <ul
        v-if="menuItems"
        key="menuItems"
        v-start-with-html="menuItems"
        class="c-page-menu__list u-flex u-m--0 u-ml--a u-mr--a u-bare-list"
      >
        <RootLink
          v-if="model.rootLink.text"
          :model="model"
        />
      </ul>
    </div>
    <ul
      v-if="dropdownContent"
      class="c-page-menu__sub-list c-page-menu__sub-list--top-level u-font-size-large u-themeable-bg u-themeable-darken"
      :class="{
        'is-parent-sticky': isSticky,
        'u-width-100': $mq !== 'desktop'
      }"
      :style="{
        '--bg-color': model.backgroundColor || '#fff',
        left: `${leftOffset}px`
      }"
    >
      <SubItems
        v-for="(subMenu, index) in dropdownContent"
        :key="index"
        :model="model"
        :items="subMenu.children"
        :sub-menu-item="subMenu.link"
      />
    </ul>
    <transition name="fade">
      <span
        v-if="dropdownContent"
        class="c-page-menu__overlay"
        @click="hideDropdownMenu"
      />
    </transition>
  </div>
</template>

<script>
import RootLink from '@/CVI/WebCore/components/PageMenuRootLink.vue'
import SubItems from '@/CVI/WebCore/components/PageMenuSubItems.vue'
import getOffsetTop from '@/CVI/WebCore/utils/offset-top'

let oldIndex = null

export default {
  components: {
    RootLink,
    SubItems
  },
  directives: {
    startWithHtml: {
      inserted(el, binding) {
        el.insertAdjacentHTML('beforeend', binding.value)
      }
    }
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    slotContent: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      menuOffset: null,
      headerHeight: 60,
      crisisBannerHeight: 0,
      anchorTargets: {},
      header: null,
      body: null,
      isSticky: false,
      isGlobalHeaderHidden: false,
      isBrandPage: this.model.rootLink,
      isMenuOffsetChanged: false,
      menuItems: this.slotContent.trim(),
      dropdownContent: null,
      leftOffset: null
    }
  },
  mounted() {
    this.header = document.querySelector('.c-header')
    this.body = document.querySelector('body')
    this.isGlobalHeaderHidden = this.header.classList.contains('is-hidden-by-brand')
    if (this.$mq === 'desktop') {
      this.$refs.menuWrapper.style.height = `${this.$refs.menu.clientHeight}px`
    }
    setTimeout(() => {
      this.menuOffset = this.$refs.menuWrapper.offsetTop
      this.handleScroll()
      this.checkPageMenuState()
    }, 200)
    const crisisBanner = document.getElementsByClassName('c-crisis-banner')
    if (crisisBanner.length) {
      this.crisisBannerHeight = crisisBanner[0].clientHeight
    }

    this.initAnchorTargets()
    document.querySelectorAll('.c-page-menu__list a').forEach((item) => {
      item.addEventListener('click', (event) => {
        this.scrollToLink(item, event)
      })
    })
    window.addEventListener('scroll', this.handleScroll)
    this.actOnMenuItems()
    document.addEventListener('mouseover', this.documentMove)
    this.hideDropDownOnScrollInMenu()
  },
  methods: {
    hideDropDownOnScrollInMenu() {
      this.$refs.menu.addEventListener('scroll', () => {
        if (this.$mq == 'desktop') {
          this.hideDropdownMenu()
        }
      })
    },
    checkIfTargetInViewport(el) {
      const scroll = window.scrollY || window.pageYOffset
      const boundsTop = el.offsetTop

      return (
        scroll + this.headerHeight >= boundsTop
        && scroll + this.headerHeight < boundsTop + el.clientHeight / 2
      )
    },
    checkIfLinkOutOfViewport(link) {
      const {
        top, left, bottom, right
      } = link.getBoundingClientRect()

      const out = {}
      out.top = top < 0
      out.left = left < 0
      out.bottom = bottom > (window.innerHeight || document.documentElement.clientHeight)
      out.right = right > (window.innerWidth || document.documentElement.clientWidth)
      out.any = out.top || out.left || out.bottom || out.right
      out.all = out.top && out.left && out.bottom && out.right

      return out
    },
    getAnchorValue({ link }) {
      return link.href.split('#')[1]
    },
    initAnchorTargets() {
      this.model.items.forEach((link) => {
        const target = this.getAnchorValue(link)

        if (target) {
          const linkTarget = link.link.href.split('/').pop()
          this.anchorTargets[linkTarget] = document.querySelector(`[id="${target}"]`)
        }
      })
    },
    handleScroll() {
      this.model.items.forEach((link) => {
        const anchor = this.getAnchorValue(link)
        if (anchor && this.anchorTargets[link.href]) {
          link.isActive = this.checkIfTargetInViewport(this.anchorTargets[link.href])
          if (this.$mq === 'mobile') {
            document.querySelectorAll('.c-page-menu__list a').forEach((item) => {
              const activeLink = item.classList.contains('is-active')
              if (activeLink) {
                if (this.checkIfLinkOutOfViewport(item).right) {
                  this.$refs.menu.scrollLeft = item.offsetLeft + item.offsetWidth
                } else if (this.checkIfLinkOutOfViewport(item).left) {
                  this.$refs.menu.scrollLeft = item.offsetLeft
                }
              }
            })
          }
        }
      })

      this.checkHeaderState()

      if (window.scrollY + this.crisisBannerHeight > this.menuOffset) {
        this.isSticky = true
      } else if (this.isGlobalHeaderHidden && (window.scrollY + this.crisisBannerHeight > this.menuOffset)) {
        this.isSticky = true
      } else {
        this.isSticky = false
      }
    },
    checkPageMenuState() {
      if (window.scrollY >= 0 && this.$mq !== 'desktop') {
        this.body.style.paddingTop = `${this.$refs.menu.clientHeight}px`
      }
    },
    checkHeaderState() {
      if (!this.header.classList.contains('is-hidden-by-brand') && !this.isMenuOffsetChanged && !this.body.classList.contains('brand-page')) {
        this.menuOffset += this.headerHeight
        this.isMenuOffsetChanged = true
      }
    },
    scrollToLink(link, event) {
      const [, anchor] = link.href.split('#')
      const linkTarget = link.href.split('/').pop()

      if (anchor && this.anchorTargets[linkTarget]) {
        event.preventDefault()
        if (this.$mq === 'mobile') {
          this.$refs.menu.scrollLeft = this.getMenuItem(event).offsetLeft
        }
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: getOffsetTop(this.anchorTargets[linkTarget])
        })
      }
    },
    getMenuItem(event) {
      return event.target.offsetParent
    },
    getMenuItemIndex(item) {
      return Array.from(item.parentElement.children).indexOf(item)
    },
    showDropdown(index, event) {
      this.removeActiveClass()
      if (oldIndex !== index) {
        oldIndex = this.getMenuItemIndex(this.getMenuItem(event))
        if (this.$mq !== 'desktop') {
          this.$refs.menu.scrollLeft = this.getMenuItem(event).offsetLeft
        }
        event.target.classList.add('is-active')
        let newIndex = index
        if (this.model.rootLink.text) {
          newIndex = index - 1
        }
        const marginLeft = 16
        const { link, children } = this.model.items[newIndex]
        if (this.$mq == 'desktop') {
          this.leftOffset = this.getMenuItem(event).offsetLeft - this.$refs.menu.scrollLeft + marginLeft
        } else {
          this.leftOffset = 0
        }

        this.dropdownContent = [
          {
            link,
            children: []
          },
          ...children
        ]
      } else {
        this.dropdownContent = null
        oldIndex = null
      }
    },
    actOnMenuItems() {
      const subItemParents = document.querySelectorAll('.c-page-menu__list > li.has-sub-items')
      subItemParents.forEach((item) => {
        item.firstElementChild.addEventListener('click', (event) => {
          event.preventDefault()
          if (this.getMenuItem(event).classList.contains('has-sub-items')) {
            this.showDropdown(this.getMenuItemIndex(this.getMenuItem(event)), event)
          } else {
            this.hideDropdownMenu()
          }
        })
      })
    },
    removeActiveClass() {
      document.querySelectorAll('.c-page-menu__list > li a').forEach((link) => {
        link.classList.remove('is-active')
      })
    },
    hideDropdownMenu() {
      this.dropdownContent = null
      oldIndex = null
      this.removeActiveClass()
    }
  }
}
</script>
