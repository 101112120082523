<template>
  <div class="u-mb--m">
    <div
      v-for="(faq, index) in faqs"
      :key="index"
    >
      <h3>
        {{ faq.heading }}
      </h3>
      <FaqAccordion
        :faqs="faq.items"
      />
    </div>
  </div>
</template>
<script>
import FaqAccordion from '@/CVI/WebCore/components/FaqAccordion.vue'

export default {
  components: {
    FaqAccordion
  },
  props: {
    faqs: {
      type: Array,
      required: true
    }
  }
}
</script>
