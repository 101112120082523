<template>
  <div class="o-content-box">
    <div class="c-coupon-wrapper">
      <div
        class="
        c-coupon
        u-flex
        u-flex-direction-column
        u-flex-align-center
        u-width-720
        u-ml--a
        u-mr--a
        o-border-box"
      >
        <span
          v-if="isCouponUnavailable || !areCouponsAvailable"
          class="c-coupon__unavailable u-mb--s u-flex"
        >
          <Icon
            class="u-mr--xs"
            :icon="iconAlert"
            :size="16"
            :fill="'error'"
          />
          {{ model.noCouponsText }}
        </span>
        <h2 class="u-font-size-h4 u-mb--m">
          {{ model.heading }}
        </h2>
        <div
          v-if="showInputField"
          class="c-coupon__input u-flex u-flex-align-center u-mb--m"
        >
          <input
            type="text"
            class="c-text-input u-mr--s"
            :class="{
              'c-text-input--focused': isCouponGenerated,
              'c-text-input--error': isCouponUnavailable
            }"
            :value="coupon"
            readonly
            @focus="selectText($event)"
          >
          <button
            v-if="!isCouponGenerated"
            class="c-button c-button--dark"
            @click="checkCouponGeneration"
          >
            {{ model.generateButtonText }}
          </button>
          <button
            v-if="isCouponGenerated && !isCouponCodeCopied"
            class="c-button c-button--light"
            @click="copyCouponCode"
          >
            {{ model.copyButtonText }}
          </button>
          <span v-if="isCouponCodeCopied">
            <Icon
              class="u-mr--xs"
              :icon="iconCircleCheck"
              :size="24"
              :fill="'success'"
            />
            <strong class="u-font-size-medium u-text-uppercase">{{ model.codeCopiedText }}</strong>
          </span>
        </div>
        <a
          :href="model.link.url"
          :rel="model.link.openInNewWindow ? 'noopener' : null"
          :target="model.link.openInNewWindow ? '_blank' : null"
          class="c-button c-button--dark c-button--small"
          :class="{
            'disabled': !isCouponGenerated
          }"
          @click="trackCouponLinkClick"
        >
          {{ model.link.text }}
          <Icon
            class="u-ml--xs"
            :icon="iconExpand"
            :size="16"
            :stroke="'white'"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import iconCircleCheck from '@ds/svg/icons/bold/check-circle-1-bold.svg'
import iconExpand from '@ds/svg/icons/stroke/expand-6.svg'
import iconAlert from '@ds/svg/icons/bold/alert-circle-bold.svg'
import Cookies from 'js-cookie'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import copyToClipboard from '@/CVI/WebCore/lib/copy-to-clipboard'
import api from '@/CVI/WebCore/lib/api'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconCircleCheck,
      iconExpand,
      iconAlert,
      isCouponGenerated: false,
      isCouponCodeCopied: false,
      isCouponUnavailable: false,
      areCouponsAvailable: true,
      showInputField: false,
      coupon: ''
    }
  },
  async mounted() {
    try {
      await api.couponCode.validate(this.model.id)
      this.showInputField = true
    } catch (e) {
      this.areCouponsAvailable = false
    }
  },
  methods: {
    checkCouponGeneration() {
      if (Cookies.get('coupon') !== undefined) {
        this.coupon = Cookies.get('coupon')
        this.isCouponGenerated = true
        this.trackCouponGenerateClick()
      } else {
        this.generateCouponCode()
      }
    },
    async generateCouponCode() {
      try {
        const { data } = await api.couponCode.get(this.model.id)
        const { code } = data
        this.coupon = code
        this.isCouponGenerated = true
        Cookies.set('coupon', this.coupon)
        this.trackCouponGenerateClick()
      } catch (e) {
        this.isCouponUnavailable = true
      }
    },
    copyCouponCode() {
      copyToClipboard.copy(this.coupon)
      this.isCouponCodeCopied = true
      tracker.trackSimpleEvent({
        event: 'copyCouponClick'
      })
    },
    selectText(e) {
      e.target.select()
    },
    trackCouponGenerateClick() {
      tracker.trackSimpleEvent({
        event: 'generateCouponClick'
      })
    },
    trackCouponLinkClick() {
      tracker.trackSimpleEvent({
        event: 'redeemCouponClick'
      })
    }
  }
}
</script>
