<template>
  <GridLoadMore
    ref="grid"
    v-bind="{...$props }"
    :components="components"
    @callApi="callApi"
  />
</template>

<script>
import GridLoadMore from '@/CVI/WebCore/components/GridLoadMore.vue'
import RecipeCard from '@/CVI/Recipe/components/RecipeCard.vue'
import api from '@/CVI/Recipe/lib/api'

export default {
  components: {
    GridLoadMore
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    tagUids: {
      type: Array,
      default: () => []
    },
    excludedUids: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      components: {
        recipe: RecipeCard
      }
    }
  },
  methods: {
    async callApi({
      take,
      skip,
      tagUids,
      excludedUids,
      cancelToken
    }) {
      const { data } = await api.recipes.post({
        take,
        skip,
        tagUids,
        excludedUids,
        cancelToken
      })
      const { total, recipes } = data
      this.$refs.grid.onApiCallEnd({
        total,
        cards: recipes,
        take
      })
    }
  }
}
</script>
