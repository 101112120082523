<template>
  <div class="c-article-grid__wrapper">
    <Loader
      v-if="isGridLoading"
      class="u-flex u-ml--a u-mr--a"
      size="large"
      color="medium-gray"
    />
    <div
      class="c-article-grid__container"
      :class="{ 'visible': !isGridLoading }"
    >
      <Grid
        :model="gridModel"
      />
      <div class="u-flex u-flex-justify-center">
        <button
          v-if="showMoreButton"
          ref="button"
          class="c-button c-button--light"
          :class="{
            'is-loading': isLoading
          }"
          @click="loadMore"
        >
          <span class="c-button__text">
            {{ model.seeAllButtonText }}
          </span>
          <Loader
            v-if="isLoading"
            class="c-button__loader"
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import webCoreApi from '@/CVI/WebCore/lib/api'
import api from '@/CVI/Publisher/lib/api'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import Grid from '@/CVI/WebCore/components/Grid.vue'

export default {
  components: {
    Grid,
    Loader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    tagUids: {
      type: Array,
      default: () => []
    },
    excludedUids: {
      type: Array,
      default: () => []
    },
    order: {
      type: String,
      default: 'Newest'
    }
  },
  data() {
    return {
      take: this.model.pageSize,
      skip: this.model.pageSize,
      apiUrl: 'articleCards',
      totalCount: this.model.totalCount,
      showMoreButton: this.model.pageSize < this.model.totalCount,
      isLoading: false,
      isGridLoading: false,
      cancelToken: null
    }
  },
  computed: {
    gridModel() {
      const { cards } = this.model
      const { columnCount } = this.model

      return {
        cards,
        columnCount
      }
    }
  },
  watch: {
    tagUids: {
      handler() {
        this.updateGrid()
      }
    }
  },
  methods: {
    async loadMore(event) {
      if (event && !(event instanceof KeyboardEvent)) {
        this.$refs.button.blur()
      }

      this.isLoading = true
      const {
        take, skip, tagUids, excludedUids, cancelToken, order
      } = this

      if (cancelToken) {
        cancelToken.cancel()
      }
      this.cancelToken = webCoreApi.createCancelToken()

      const { data } = await api.articleCards.get({
        take,
        skip,
        tagUids,
        excludedUids,
        order,
        cancelToken: this.cancelToken
      })

      this.skip += take
      this.totalCount = data.total
      this.showMoreButton = this.skip < this.totalCount

      this.model.cards = this.model.cards.concat(data.cards)
      this.isLoading = false
      this.isGridLoading = false
    },
    updateGrid() {
      this.isGridLoading = true
      setTimeout(() => {
        this.showMoreButton = false
        this.isGridLoading = true
        this.skip = 0
        this.model.cards = []

        this.loadMore()
      }, 200)
    }
  }
}
</script>
