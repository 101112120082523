<template>
  <div>
    <FacetMenuCloseButton />
    <h4 class="c-facet-tag-menu__title">{{ translations.heading }}</h4>
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import store from '@/CVI/Facet/store'
import facetMenu from '@/CVI/Facet/components/FacetTagMenu'
import FacetMenuCloseButton from '@/CVI/Facet/components/FacetTagMenuCloseButton.vue'

const $root = document.querySelector('.js-facet-menu')
const $accordionRoot = document.querySelector('.js-facet-tag-menu-accordion')

export default {
  components: {
    FacetMenuCloseButton
  },
  props: {
    translations: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      isFirstTimeOpened: true
    }
  },
  watch: {
    'store.state.allFiltersVisible': {
      handler(value) {
        this.toggleMenuVisiblity(value)
      }
    }
  },
  mounted() {
    this.store.dispatch('setTranslations', { translations: this.translations })
  },
  methods: {
    toggleMenuVisiblity(value) {
      if (value) {
        $root.classList.add('is-opened')
        disablePageScroll($accordionRoot)
        if (this.isFirstTimeOpened) {
          this.isFirstTimeOpened = false
          facetMenu.initMainAccordion()
          facetMenu.initOverlayClick()
        }
      } else {
        $root.classList.add('is-closing')
        setTimeout(() => {
          $root.classList.remove('is-opened', 'is-closing')
          enablePageScroll($accordionRoot)
        }, 300)
      }
    }
  }
}
</script>
