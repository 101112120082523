<template>
  <span
    class="c-product-card swiper-slide u-text-align--center"
  >
    <a :href="model.url">
      <div class="c-product-card__img u-mb--s u-mb--xs@mobile">
        <Picture
          class="c-product-card__picture"
          :src="model.image"
          :resizer="model.imageResizer.name"
          :alt="model.heading"
          :mobile="[150, 120]"
          :tablet="[250, 200]"
          :desktop="[350, 300]"
          :options="options"
          :lazy="true"
        />
      </div>
      <span class="c-product-card__label c-label--s u-mb--s u-mb--xs@mobile">
        {{ model.kicker }}</span>
      <h3 class="u-font-size-h5 u-m--0">{{ model.heading }}</h3>
      <div class="u-flex u-flex-wrap u-flex-justify-center">
        <div
          v-if="model.showLaunchLabel"
          class="c-product__label-wrapper u-ml--xxs u-mr--xxs u-mt--s"
        >
          <span
            class="c-product__label c-product__label--medium u-themeable-bg-medium-blue u-text-uppercase u-mb--xxs"
          >
            {{ model.launchLabel }}
          </span>
        </div>

        <div
          v-if="model.customLabel"
          class="c-product__label-wrapper u-mt--s"
        >
          <span
            class="c-product__label c-product__label--medium u-themeable-bg-medium-green u-text-uppercase u-mb--xxs"
          >
            {{ model.customLabel }}
          </span>
        </div>
      </div>
      <span
        v-if="model.buttonText && !model.enableWhereToBuy"
        class="c-button c-button--dark c-button--small u-mt--s"
      >{{ model.buttonText }}</span>
    </a>
    <WhereToBuyButton
      v-if="model.showWhereToBuyButton"
      :model="model"
    />
  </span>
</template>

<script>

import Picture from '@/CVI/WebCore/components/Picture.vue'
import WhereToBuyButton from '@/CVI/Product/components/WhereToBuy/WhereToBuyButton.vue'


export default {
  components: {
    Picture,
    WhereToBuyButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => ({ mode: 'max' })
    }
  }
}
</script>
