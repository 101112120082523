<template>
  <div
    :id="model.anchor"
    :style="{
      '--bg-color': model.backgroundColor}"
    class="
      c-seo-links
      o-content-box
      u-themeable-bg
    "
    :class="{
      'u-themeable-color-dark' : model.foregroundColor == 'dark',
      'u-themeable-color-light' : model.foregroundColor == 'light',
      'u-padding--b-0' : model.removePadding
    }"
  >
    <span
      class="
        c-label--m
        u-opacity-70
        u-mt--m
        u-mt--m@mobile
      "
    >
      {{ model.kicker }}
    </span>
    <h2
      class="
        u-font-size-h2
        u-m--0
        u-mt--s
        u-mb--l
        u-mb--l@mobile
      "
      v-html="model.heading"
    />
    <div
      class="
        u-flex
        u-flex-wrap
        u-mb--s
      "
    >
      <div
        v-for="(col, index) in groups"
        :key="index"
        class="c-seo-links__col u-mb--l u-mb--l@mobile"
        :class="{'visible' : isAllGroupsVisible}"
      >
        <div class="c-seo-links__col-content">
          <h4 class="u-mb--xs">
            <a
              v-if="col.url !== null"
              :href="col.url"
            >
              {{ col.headline }}
            </a>
            <span v-else>
              {{ col.headline }}
            </span>
          </h4>
          <ul
            class="u-bare-list"
          >
            <li
              v-for="link in col.links"
              :key="link.url"
              class="u-mb--s"
              :class="{
                'visible': col.isExpanded
              }"
            >
              <a :href="link.url">{{ link.text }}</a>
            </li>
          </ul>
          <span
            v-if="!col.isExpanded && col.links.length > 5"
            class="c-seo-links__col-see-all u-text-underline"
            @click="col.isExpanded = true"
          >
            {{ model.showAllLinksText }}
            ({{ col.links.length - 5 }})
            <Icon
              class="u-ml--xxs"
              :icon="iconArrowDown"
              :size="12"
              :fill="iconColor"
            />
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="$mq !== 'desktop' && !isAllGroupsVisible"
      class="u-text-align--center"
    >
      <button
        class="c-button c-button--dark c-button--small"
        @click="isAllGroupsVisible = true"
      >
        {{ model.showAllGroupsText }}
      </button>
    </div>
  </div>
</template>
<script>
import iconArrowDown from '@ds/svg/icons/bold/arrow-down-1-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      groups: this.model.groups,
      iconColor: '',
      iconArrowDown,
      isAllGroupsVisible: this.model.showAllGroups
    }
  },
  mounted() {
    this.calculateFillIcon()
  },
  methods: {
    calculateFillIcon() {
      if (this.model.foregroundColor == 'light') {
        this.iconColor = 'white'
      } else {
        this.iconColor = 'black'
      }
    }
  }
}
</script>
