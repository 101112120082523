<template>
  <tr>
    <td>
      <strong>{{ row.header }}</strong>
      <div class="u-flex u-mt--xs">
        <span>{{ defaultColumnTitle }}</span>
        <span class="u-ml--a">{{ row.defaultValue }}</span>
      </div>
      <div
        v-if="row.servingValue"
        class="u-flex"
      >
        <span>{{ servingColumnTitle }}</span>
        <span class="u-ml--a">{{ row.servingValue }}</span>
      </div>
    </td>
  </tr>
</template>
<script>
export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    defaultColumnTitle: {
      type: String,
      default: ''
    },
    servingColumnTitle: {
      type: String,
      default: ''
    }
  }
}
</script>
