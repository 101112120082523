<template>
  <tr>
    <template v-if="row.subRow">
      <td>
        <span class="u-ml--s">{{ row.header }}</span>
      </td>
      <td>
        <span class="u-ml--s">{{ row.defaultValue }}</span>
      </td>
      <td v-if="hasPerServing">
        <span class="u-ml--s">
          {{ row.servingValue }}
        </span>
      </td>
    </template>
    <template v-else>
      <td>
        {{ row.header }}
      </td>
      <td>{{ row.defaultValue }}</td>
      <td v-if="hasPerServing">
        {{ row.servingValue }}
      </td>
    </template>
  </tr>
</template>
<script>
export default {
  props: {
    row: {
      type: Object,
      required: true
    },
    hasPerServing: {
      type: Boolean,
      default: false
    }
  }
}
</script>
