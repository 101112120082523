<template>
  <div
    class="c-product-tab__nutrition-summary-col"
    :class="factClass"
  >
    <strong class="u-mb--s">{{ model.title }}</strong>
    <span class="c-product-tab__nutrition-summary-weight u-mb--s">{{ model.value }}</span>
    <span
      class="c-product-tab__nutrition-summary-level u-ml--a u-mr--a u-text-uppercase"
    >
      {{ model.levelText }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    factClass() {
      if (this.model.level) {
        return `nutrition-${this.model.level.toLowerCase()}`
      }
      return ''
    }
  }
}
</script>
