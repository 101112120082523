<template>
  <BuyButton
    :model="buyButtonModel"
    :show-logos="showLogos"
    :guide-visible="guideVisible"
    :vendors-title="vendorsTitle"
    :lazy="lazy"
  />
</template>

<script>
import BuyButton from '@/CVI/Purchase/components/BuyButtonWrapper.vue'
import recipeStore from '@/CVI/Recipe/store'

export default {
  components: {
    BuyButton
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    showLogos: {
      type: Boolean,
      default: true
    },
    guideVisible: {
      type: Boolean,
      default: true
    },
    vendorsTitle: {
      type: String,
      default: ''
    },
    lazy: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      recipeStore,
      buyButtonModel: this.model
    }
  },
  watch: {
    'recipeStore.state.portionCount': {
      handler(value) {
        this.buyButtonModel.portionCount = value
      }
    }
  },
  created() {
    this.recipeStore.state.portionCount = this.model.button.portionCount
  }
}
</script>
