<template>
  <div
    v-show="visible"
    :id="id"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  }
}
</script>
