const noFollowLink = () => {
  document.querySelectorAll('.js-no-follow').forEach((link) => {
    link.addEventListener('click', () => {
      const url = link.dataset.href
      window.location = url
    })
  })
}

export default noFollowLink
