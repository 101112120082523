<template>
  <div class="с-grid-load-more">
    <Loader
      v-if="isGridLoading"
      class="u-flex u-ml--a u-mr--a"
      size="large"
      color="medium-gray"
    />
    <div
      class="с-grid-load-more__container"
      :class="{ 'is-visible': !isGridLoading }"
    >
      <Grid
        :model="gridModel"
        :components="components"
      />
      <div class="u-flex u-flex-justify-center">
        <button
          v-if="showMoreButton"
          ref="button"
          class="c-button c-button--light"
          :class="{
            'is-loading': isLoading
          }"
          @click="loadMore"
        >
          <span class="c-button__text">
            {{ model.seeAllButtonText }}
          </span>
          <Loader
            v-if="isLoading"
            class="c-button__loader"
          />
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import webCoreApi from '@/CVI/WebCore/lib/api'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import Grid from '@/CVI/WebCore/components/Grid.vue'

export default {
  components: {
    Grid,
    Loader
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    tagUids: {
      type: Array,
      default: () => []
    },
    excludedUids: {
      type: Array,
      default: () => []
    },
    components: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const { pageSize, totalCount } = this.model
    return {
      take: pageSize,
      skip: pageSize,
      totalCount,
      showMoreButton: pageSize < totalCount,
      isLoading: false,
      isGridLoading: false,
      cancelToken: null
    }
  },
  computed: {
    gridModel() {
      const { cards } = this.model
      const { columnCount } = this.model

      return {
        cards,
        columnCount
      }
    }
  },
  watch: {
    tagUids: {
      handler() {
        this.updateGrid()
      }
    }
  },
  methods: {
    async loadMore(event) {
      if (event && !(event instanceof KeyboardEvent)) {
        this.$refs.button.blur()
      }

      this.isLoading = true
      const {
        take, skip, tagUids, excludedUids, cancelToken
      } = this

      if (cancelToken) {
        cancelToken.cancel()
      }
      this.cancelToken = webCoreApi.createCancelToken()

      this.$emit('callApi', {
        take,
        skip,
        tagUids,
        excludedUids,
        cancelToken: this.cancelToken
      })
    },
    onApiCallEnd({ total, cards, take }) {
      this.skip += take
      this.totalCount = total
      this.showMoreButton = this.skip < this.totalCount

      this.model.cards = this.model.cards.concat(cards)
      this.isLoading = false
      this.isGridLoading = false
    },
    updateGrid() {
      this.isGridLoading = true
      setTimeout(() => {
        this.showMoreButton = false
        this.isGridLoading = true
        this.skip = 0
        this.model.cards = []

        this.loadMore()
      }, 200)
    }
  }
}
</script>

<style lang="scss">
@use '@ds/styles/tools/responsive';

.с-grid-load-more {
  min-height: 360px;
  flex-direction: row-reverse;

  @include responsive.mq($until: desktop) {
    min-height: 240px;
    flex-direction: column;
  }
}

.с-grid-load-more__container {
  opacity: 0;
  transition: opacity .2s ease;

  &.is-visible {
    opacity: 1;
  }
}
</style>
