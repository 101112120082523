<template>
  <a
    class="c-page-card swiper-slide"
    :href="model.url"
    :target="urlTarget"
    :rel="urlRel"
  >
    <div class="c-page-card__img u-mb--s">
      <Picture
        class="c-page-card__picture"
        :src="model.image"
        :resizer="model.imageResizer.name"
        :alt="model.heading"
        :mobile="[150, 120]"
        :tablet="[250, 200]"
        :desktop="[460, 250]"
        :wide="[600, 400]"
        :lazy="true"
        :options="{ mode: 'crop'}"
      />
    </div>
    <span class="c-page-card__label c-label--s u-mb--xs u-ml--xxs">{{ model.kicker }}</span>
    <h3 class="u-font-size-h4 u-ml--xxs">{{ model.heading }}</h3>
  </a>
</template>

<script>
import Picture from '@/CVI/WebCore/components/Picture.vue'

export default {
  components: {
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    urlTarget() {
      return this.model.openInNewWindow ? '_blank' : null
    },
    urlRel() {
      return this.model.openInNewWindow ? 'noopener noreferrer' : null
    }
  }
}
</script>
