<template>
  <div class="c-accordion-item">
    <div
      ref="title"
      role="button"
      tabindex="0"
      :aria-expanded="value == uniqId"
      class="c-accordion-item__title u-flex u-flex-align-center"
      @keypress.enter="toggle($event)"
      @click="toggle($event)"
    >
      <slot name="title" />
      <Icon
        v-if="!isOpened"
        class="c-accordion-item__title-icon u-ml--a"
        :icon="iconOpen"
        :size="16"
        fill="black"
      />
      <Icon
        v-else
        class="c-accordion-item__title-icon u-ml--a"
        :icon="iconClose"
        :size="16"
        fill="black"
      />
    </div>
    <div
      v-show="isOpened"
      class="c-accordion-item__content"
    >
      <slot />
    </div>
  </div>
</template>

<script>
import iconOpen from '@ds/svg/icons/bold/add-bold.svg'
import iconClose from '@ds/svg/icons/bold/subtract-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import tracker from '@/CVI/WebCore/core-tracker'

const headerHeight = 60

export default {
  components: {
    Icon
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    trackingType: {
      type: String,
      default: ''
    },
    preOpened: {
      type: Boolean,
      default: false
    },
    updateUrl: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconOpen,
      iconClose,
      crisisBannerHeight: 0,
      pageMenuHeight: 0
    }
  },
  computed: {
    isOpened() {
      return this.value == this.uniqId
    },
    uniqId() {
      return `${this.id}-${this._uid}`
    }
  },
  beforeMount() {
    if (this.preOpened) {
      this.$emit('input', this.uniqId)
    }
  },
  mounted() {
    if (this.updateUrl && window.location.hash == `#${this.id}`) {
      this.$emit('input', this.uniqId)
      window.addEventListener('load', () => {
        this.scrollToFaqItem()
      })
    }
  },
  methods: {
    toggle(event) {
      if (this.value == this.uniqId) {
        this.$emit('input', '')
      } else {
        this.$emit('input', this.uniqId)
        if (this.updateUrl) {
          window.location.hash = this.id
        }
        this.$nextTick(() => {
          if (event instanceof MouseEvent) {
            this.$refs.title.blur()
          }
          this.scrollToItemIfNeeded()
        })
        if (this.trackingType === 'faqItem') {
          tracker.trackAccordionItemClick({
            event: 'faqClick',
            faqName: event.target.innerText
          })
        }
        if (this.trackingType === 'productAccordion') {
          tracker.trackTabItemClick({
            event: 'tabClick',
            tabName: event.target.innerText
          })
        }
      }
      this.$nextTick(() => {
        if (event instanceof MouseEvent) {
          this.$refs.title.blur()
        }
      })
    },
    scrollToItemIfNeeded() {
      if (this.$refs.title.getBoundingClientRect().top < 0) {
        window.scrollTo(0, this.$refs.title.offsetTop - headerHeight)
      }
    },
    scrollToFaqItem() {
      const crisisBanner = document.querySelector('.c-crisis-banner')
      const pageMenu = document.querySelector('.c-page-menu__wrapper.has-global-navigation-hidden')
      if (crisisBanner) {
        this.crisisBannerHeight = crisisBanner.clientHeight
      }
      if (pageMenu) {
        this.pageMenuHeight = pageMenu.clientHeight
      }
      window.scrollTo({
        left: 0,
        top: this.$refs.title.offsetTop - headerHeight - this.crisisBannerHeight - this.pageMenuHeight,
        behavior: 'smooth'
      })
    },
    open() {
      this.$emit('input', this.uniqId)
    },
    close() {
      this.$emit('input', '')
    }
  }
}
</script>
