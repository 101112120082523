<template>
  <SignUp
    v-bind="{...$props, ...$attrs}"
  />
</template>

<script>

export default {
  components: {
    SignUp: () => import('@/CVI/WebCore/components/SignUp.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    blockId: {
      type: Number,
      required: true
    }
  }
}
</script>
