<template>
  <div class="c-sub-menu u-flex u-flex-align-center u-flex-justify-center u-hidden-print">
    <div class="c-sub-menu__list-holder u-flex">
      <div class="c-sub-menu__list u-flex">
        <a
          v-for="(link, index) in links"
          :key="index"
          class="c-radio-group__button c-radio-group__button--alternative"
          :class="{
            'is-active': link.isActive
          }"
          @click="scrollToLink(link)"
        >
          {{ link.title }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/CVI/Recipe/store'
import tracker from '@/CVI/WebCore/core-tracker'
import getOffsetTop from '@/CVI/WebCore/utils/offset-top'

export default {
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      store,
      links: []
    }
  },
  mounted() {
    this.findLinks()
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const windowOffset = 150

      this.links.forEach((link) => {
        const offset = link.target.getBoundingClientRect().y
        const height = link.target.offsetHeight

        if (offset < windowOffset && offset + height > window.innerHeight / 2) {
          link.isActive = true
        } else {
          link.isActive = false
        }
      })
    },
    scrollToLink(link) {
      const headerHeight = 60
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: getOffsetTop(link.target) - headerHeight
      })
      tracker.trackSimpleEvent({
        event: link.trackingEventName
      })
    },
    findLinks() {
      const links = []
      document.querySelectorAll('[data-scroll-to]').forEach((element) => {
        links.push({
          target: element,
          title: element.dataset.scrollTo,
          isActive: false,
          trackingEventName: element.dataset.trackingEventName
        })
      })
      this.links = links.reverse()
    }
  }
}
</script>
