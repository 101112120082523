<template>
  <span
    class="c-button c-button--small c-button--no-hover c-button--dark u-text-nowrap"
  >
    {{ model.name }}
    <button
      class="c-button--no-styles c-facet__remove-tag"
      @click="removeTag()"
    >
      <Icon
        class="u-ml--xs"
        :icon="iconClose"
        :size="12"
        fill="white"
        hover-color="white"
      />
    </button>
  </span>
</template>

<script>
import iconClose from '@ds/svg/icons/bold/close-bold.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconClose
    }
  },
  methods: {
    removeTag() {
      this.$emit('removeTag', this.model)
    }
  }
}
</script>
