<template>
  <div>
    <div
      class="o-content-box--side u-flex"
    >
      <Tab
        v-model="activeTab"
        :title="model.nutritionTabTitle"
        href="#nutrition"
        class="u-mr--l"
      />
      <Tab
        v-if="model.storageContent"
        v-model="activeTab"
        :title="model.storageTabTitle"
        href="#storage"
        class="u-mr--l"
      />
      <Tab
        v-if="model.recyclingContent"
        v-model="activeTab"
        :title="model.recyclingTabTitle"
        href="#recycling"
        class="u-mr--l"
      />
      <Tab
        v-if="model.additionalInformationContent"
        v-model="activeTab"
        :title="model.additionalInformationTabTitle"
        href="#additionalInformation"
        class="u-mr--l"
      />
      <Tab
        v-if="model.faqs && model.faqs.length"
        v-model="activeTab"
        :title="model.faqTabTitle"
        href="#faq"
        class="u-mr--l"
      />
    </div>
    <div class="c-tab-content o-content-box">
      <TabContent
        id="nutrition"
        :visible="activeTab == 'nutrition'"
      >
        <Nutrition
          :model="model.nutrition"
        />
      </TabContent>
      <TabContent
        id="storage"
        :visible="activeTab == 'storage'"
      >
        <div class="u-font-size-xlarge u-rich-text">
          <h3>{{ model.storageTabHeading }}</h3>
          <div v-html="model.storageContent"></div>
        </div>
      </TabContent>
      <TabContent
        v-if="model.recyclingContent"
        id="recycling"
        :visible="activeTab == 'recycling'"
      >
        <div class="u-font-size-xlarge u-rich-text">
          <h3>{{ model.recyclingTabHeading }}</h3>
          <div v-html="model.recyclingContent"></div>
        </div>
      </TabContent>
      <TabContent
        v-if="model.additionalInformationContent"
        id="additionalInformation"
        :visible="activeTab == 'additionalInformation'"
      >
        <div class="u-font-size-xlarge u-rich-text">
          <h3>{{ model.additionalInformationTabHeading }}</h3>
          <div v-html="model.additionalInformationContent"></div>
        </div>
      </TabContent>
      <TabContent
        v-if="model.faqs && model.faqs.length"
        id="faq"
        :visible="activeTab == 'faq'"
      >
        <Faq
          :faqs="model.faqs"
        />
      </TabContent>
    </div>
  </div>
</template>

<script>
import Tab from '@/CVI/WebCore/components/Tab.vue'
import TabContent from '@/CVI/WebCore/components/TabContent.vue'
import Nutrition from '@/CVI/Product/components/Nutrition.vue'
import Faq from '@/CVI/Product/components/Faq.vue'

export default {
  components: {
    Tab,
    TabContent,
    Nutrition,
    Faq
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'nutrition'
    }
  }
}
</script>
