<template>
  <EditorialRenderless :model="model">
    <a
      ref="link"
      slot-scope="{ urlTarget, urlRel, trackClick }"
      :class="containerClass"
      :href="model.url"
      :target="urlTarget"
      :rel="urlRel"
      @click="trackClick"
    >
      <Picture
        class="c-campaign-image__picture"
        :src="model.image"
        :resizer="model.imageResizer.name"
        :alt="model.name"
        :mobile="pictureSize"
        :tablet="pictureSize"
        :desktop="pictureSize"
        :wide="pictureSize"
        :options="{ mode: 'crop'}"
      />
    </a>
  </EditorialRenderless>
</template>

<script>
import Picture from '@/CVI/WebCore/components/Picture.vue'
import EditorialRenderless from '@/CVI/Recipe/components/EditorialRenderless.vue'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    EditorialRenderless,
    Picture
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    const { item1, item2 } = this.model.imageSize
    return {
      pictureSize: [item1, item2],
      isNarrow: item2 == 100
    }
  },
  computed: {
    containerClass() {
      const classArr = ['c-campaign-image', 'u-mb--s']
      if (this.isNarrow) {
        classArr.push('c-campaign-image--narrow')
      }
      if (this.model.mobileOnly) {
        classArr.push('u-hidden@desktop')
      }
      if (this.model.desktopOnly) {
        classArr.push('u-hidden@mobile')
      }

      return classArr
    }
  },
  mounted() {
    if (this.model.trackingData) {
      tracker.trackDisplayItemImpression(this.$refs.link, this.model.trackingData)
    }
  }
}
</script>
