import axios from 'axios'

const language = document.documentElement.lang

export default {
  recipes: {
    baseUrl: `/cvi/facet/api/${language}/recipes`,
    get({
      skip = 0, tags = [], sortOrder, searchTerms = [], cancelToken
    }) {
      const searchParams = new URLSearchParams()
      searchParams.append('skip', skip)
      tags.forEach((tag) => {
        searchParams.append('tags', tag)
      })
      searchParams.append('sortOrder', sortOrder)
      searchTerms.forEach((term) => {
        searchParams.append('searchTerms', term)
      })

      return axios.get(this.baseUrl, {
        cancelToken: cancelToken?.token,
        params: searchParams
      })
    }
  },
  search: {
    baseUrl: `/cvi/facet/api/${language}/searchtags`,
    get({ query, tagUids, cancelToken }) {
      const searchParams = new URLSearchParams()
      searchParams.append('query', query)
      tagUids.forEach((uid) => {
        searchParams.append('tagUids', uid)
      })

      return axios.get(this.baseUrl, {
        cancelToken: cancelToken?.token,
        params: searchParams
      })
    }
  }
}
