<template>
  <li
    ref="line"
    class="c-recipe__instructions-step u-ml--s"
    :class="{
      'u-text-medium-gray': step.completed
    }"
  >
    <span
      v-if="showCheckbox"
      class="c-recipe__instructions-step-icon u-hidden-print"
    >
      <Checkbox
        v-model="step.completed"
      />
    </span>
    <span>{{ step.text }}</span>
  </li>
</template>
<script>
import Checkbox from '@/CVI/WebCore/components/Checkbox.vue'
import tracker from '@/CVI/WebCore/core-tracker'
import getOffsetTop from '@/CVI/WebCore/utils/offset-top'

export default {
  components: {
    Checkbox
  },
  props: {
    step: {
      type: Object,
      required: true
    },
    showCheckbox: {
      type: Boolean,
      default: false
    },
    scrollToLine: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    'step.completed': {
      handler(isCompleted) {
        if (isCompleted) {
          tracker.trackRecipeStep({
            event: 'checkRecipeStep',
            recipeStepNumber: parseInt(this.step.id, 0) + 1
          })
        }
      }
    }
  },
  mounted() {
    if (this.scrollToLine) {
      this.$nextTick(() => {
        this.scrollTo()
      })
    }
  },
  methods: {
    scrollTo() {
      const { line } = this.$refs
      const headerHeight = 60
      const stickyBarHeight = this.$mq == 'mobile' ? 0 : 90
      window.scroll({
        left: 0,
        top: getOffsetTop(line) - headerHeight - stickyBarHeight
      })
    }
  }
}

</script>
