import './scss/shopping-cart.scss'
import './scss/product-line.scss'
import './scss/change-amount.scss'
import './scss/amount-input.scss'
import './scss/swap-products-modal.scss'
import './scss/add-recipe-card.scss'
import './scss/mealplan-weeks.scss'
import './scss/single-mealplan.scss'

import vueUtil from '@/CVI/WebCore/vue-util'
import PurchaseBuyButtonWithGuideLoader from '@/CVI/Purchase/components/BuyButtonWrapper.vue'
import PurchaseBuyButton from '@/CVI/Purchase/components/BuyButton.vue'
import PurchaseBuyButtonWithText from '@/CVI/Purchase/components/BuyButtonWithText.vue'
import PurchaseBuyButtonMultiple from '@/CVI/Purchase/components/BuyButtonMultiple.vue'
import PurchaseShoppingCartLoader from '@/CVI/Purchase/components/ShoppingCartLoader.vue'

import PurchaseMealPlanWeeks from '@/CVI/Purchase/components/MealPlan/MealPlanWeeks.vue'
import PurchaseCustomMealPlanLoader from '@/CVI/Purchase/components/CustomMealPlan/AppLoader.vue'
import PurchaseCustomMealPlanWidget from '@/CVI/Purchase/components/CustomMealPlan/WidgetLoader.vue'
import purchaseStore from '@/CVI/Purchase/store'
import PurchaseMealPlanButtons from '@/CVI/Purchase/components/MealPlan/MealPlanButtons.vue'

// CODE FOR OPTIMIZE
// eslint-disable-next-line
window.__purchaseStore = purchaseStore

const components = {
  PurchaseShoppingCartLoader,
  PurchaseBuyButton,
  PurchaseBuyButtonWithGuideLoader,
  PurchaseBuyButtonWithText,
  PurchaseBuyButtonMultiple,
  PurchaseCustomMealPlanLoader,
  PurchaseMealPlanWeeks,
  PurchaseCustomMealPlanWidget,
  PurchaseMealPlanButtons
}

vueUtil.mountComponents(components)
