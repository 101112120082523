<template>
  <button
    class="c-button c-button--green u-flex-shrink-0 u-hidden-print"
    @click="onButtonClick"
  >
    <Icon
      class="u-mr--xs"
      :icon="iconCart"
      :size="16"
      :stroke="'white'"
    />
    <span>{{ model.text }}</span>
  </button>
</template>

<script>
import iconCart from '@ds/svg/icons/stroke/shopping-basket-1.svg'
import Icon from '@/CVI/WebCore/components/Icon.vue'
import store from '@/CVI/Purchase/store'
import webcoreStore from '@/CVI/WebCore/store'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  components: {
    Icon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      iconCart,
      store,
      webcoreStore
    }
  },
  methods: {
    onButtonClick() {
      const { uid, portionCount } = this.model.button
      this.store.dispatch('addRecipesToCart', [{
        uid,
        portionCount
      }])
      this.webcoreStore.state.isShoppingPanelVisible = true
      if (this.model.button.trackingData) {
        tracker.trackAddToCart(this.model.button.trackingData)
      }
    }
  }
}
</script>
