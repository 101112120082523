<template>
  <UserCockpit :model="model" />
</template>

<script>
export default {
  components: {
    UserCockpit: () => import('@/CVI/CustomerWeb/components/UserCockpit.vue')
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>
