<template>
  <div
    id="recaptcha"
    data-size="invisible"
  />
</template>

<script>
export default {
  props: {
    sitekey: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.renderCaptcha()

    const { sitekey } = this

    const getCaptchaResponse = (response) => {
      if (response) {
        this.$emit('submit', response)
      }
    }

    window.renderCaptcha = () => {
      if (window.grecaptcha) {
        window.grecaptcha.render('recaptcha', {
          sitekey,
          callback: getCaptchaResponse
        })
      }
    }
  },
  methods: {
    renderCaptcha() {
      const recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=renderCaptcha&render=explicit')
      document.head.appendChild(recaptchaScript)
    },
    verify() {
      if (window.grecaptcha) {
        const response = window.grecaptcha.getResponse()
        if (response.length == 0) {
          window.grecaptcha.execute()
        } else {
          this.$emit('submit', response)
        }
      }
    }
  }
}
</script>
