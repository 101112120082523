<template>
  <a
    class="c-button"
    :class="model.class"
    @click="scrollTo"
  >
    {{ model.link.text }}
  </a>
</template>

<script>
import getOffsetTop from '@/CVI/WebCore/utils/offset-top'
import tracker from '@/CVI/WebCore/core-tracker'

export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    trackingObj: {
      type: Object,
      default: null
    }
  },
  methods: {
    scrollTo(e) {
      e.preventDefault()
      const { trackingObj } = this
      const headerHeight = 60
      const element = document.querySelector(this.model.link.url)
      if (element) {
        window.scroll({
          behavior: 'smooth',
          left: 0,
          top: getOffsetTop(element) - headerHeight
        })
        if (trackingObj) {
          tracker.track({ ...trackingObj })
        }
      }
    }
  }
}
</script>
