var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FaceMenuTag',{attrs:{"model":_vm.model},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var addTag = ref.addTag;
var removeTag = ref.removeTag;
var isSelected = ref.isSelected;
return _c('div',{ref:"container",staticClass:"u-flex u-flex-align-baseline"},[_c(_vm.model.indexed ? 'a' : 'span',{tag:"component",staticClass:"c-button c-button--small u-text-nowrap",class:{
        'c-button--no-hover c-button--dark': isSelected,
        'c-button--light': !isSelected
      },style:({
        'pointer-events': _vm.disabled ? 'none' : null
      }),attrs:{"href":_vm.model.indexed ? _vm.model.url : null,"disabled":_vm.disabled},on:{"click":function($event){$event.preventDefault();return addTag($event)}}},[_vm._v(" "+_vm._s(_vm.model.name)+" "),(isSelected)?_c('span',{staticClass:"c-button--no-styles c-facet__remove-tag u-flex-inline u-flex-align-center",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return removeTag($event)}}},[_c('Icon',{staticClass:"u-ml--xs",attrs:{"icon":_vm.iconClose,"size":12,"fill":isSelected ? 'white' : 'dark',"hover-color":"gray"}})],1):_vm._e()]),(_vm.model.children.length)?_c('button',{staticClass:"c-facet-tag-menu__children-tag-toggler c-button--no-styles u-flex-inline u-font-size-medium u-ml--s",class:{
        'is-opened': _vm.isOpened
      },on:{"click":_vm.toggleChildren}},[(_vm.isOpened)?[_vm._v(" "+_vm._s(_vm.store.state.translations.showLessText)+" ")]:[_vm._v(" "+_vm._s(_vm.store.state.translations.showMoreText)+" ")]],2):_vm._e()],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }